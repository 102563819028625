import * as fabric from 'fabric'
import { appendArrows } from './arrows'
import { getAbsoluteCenterPosition } from '../util'

export function moveAnchoredLine(line, anchorStart, anchorEnd, selection) {
  if (selection?.type === "activeselection") {
    selection.remove(line)
  }
  const group = line.group
  const textbox = group._objects.find((obj) => obj.type === "textbox")
  if (group.isLineContainer) {
    group.remove(line, textbox)
  }

  if (anchorStart) updateAbsolutePoints(line, anchorStart, true)
  if (anchorEnd) updateAbsolutePoints(line, anchorEnd, false)
  const { x1, y1, x2, y2 } = line.absolutePoints
  if (line.path) { // Arrow
    const pathWithArrows = appendArrows(
      `M0,0 L${x2 - x1},${y2 - y1}`,
      line.path.length > 6, // start arrow only if double arrow
      true, // end arrow
      line.strokeWidth * 4 // arrow length is "4 strokeWidths"
    )

    const newArrow = new fabric.Path(pathWithArrows, {
      strokeWidth: line.strokeWidth,
      strokeLineCap: 'round',
      stroke: line.stroke,
      top: Math.min(y1, y2),
      left: Math.min(x1, x2),
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      objectCaching: false,
    })
 
    line.set({ ...newArrow })
  }
  else { // Simple line
    line.set({ x1, y1, x2, y2 })
  }
  line.setCoords()

  if (group.isLineContainer) {
    if (textbox) {
      textbox.set({
        top: y1 + (y2 - y1) / 2,
        left: x1 + (x2 - x1) / 2,
        width: x2 - x1,
      })
      group.add(line, textbox)
    }
  }
}

function updateAbsolutePoints(line, anchorPoint, moveStart) {
  const { x, y } = getAbsoluteCenterPosition(anchorPoint)
  if (moveStart) {
    line.absolutePoints.x1 = x
    line.absolutePoints.y1 = y
  }
  else {
    line.absolutePoints.x2 = x
    line.absolutePoints.y2 = y
  }
}