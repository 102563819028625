import * as fabric from 'fabric'
import log from 'loglevel'

import EventEmitter from './EventEmitter'
import './shapes'
import { moveAnchoredLine } from './tools/anchorHelper'

const MODES = ['free', 'select', 'tool', 'pan']

const MAX_ZOOM = 10
const MIN_ZOOM = 0.1

// Extra attributes that fabric should include when `.toJSON` is called on an object.
const EXTRA_SERIALIZABLE_ATTRIBUTES = ['_id', 'hasEditableText', 'localId', 'isLineContainer', 'isShapeContainer', 'shapeType', 'anchorId', 'anchorStart', 'anchorEnd', 'absolutePoints', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingFlip', 'lockScalingX', 'lockScalingY', 'lockSkewingX', 'lockSkewingY', 'isAnchorGroup', 'lockStroke', 'lockFill']

class CanvasWrapper extends EventEmitter {
  constructor(canvasElement, options = {}) {
    super()

    // Overwrite the default noScaleCache value, so objects don't
    // get stretched when resizing
    const defaults = fabric.Object.getDefaults()
    fabric.Object.getDefaults = () => {
      return { ...defaults, noScaleCache: false }
    }

    this.canvasElement = canvasElement
    this.wrapperElement = canvasElement.parentElement
    this.options = options

    this.canvas = null
    this.resizeCanvas()
    this.canvas = new fabric.Canvas(this.canvasElement)
    this._clipboard = null

    this.canvas.preserveObjectStacking = true

    // Event listeners
    this.wrapperElement.addEventListener('keydown', (ev) => this.emit('event', 'key', ev))

    this.canvas.on('object:added', ({ target }) => {
      this.onObjectAdded(target)
      this.updateObjectAnchorLines(target)
    })
    this.canvas.on('object:modified', ({ target }) => {
      this.onObjectModified(target)
      this.updateObjectAnchorLines(target)
    })
    this.canvas.on('object:moving', ({ target }) => this.updateObjectAnchorLines(target))
    this.canvas.on('object:rotating', ({ target }) => this.updateObjectAnchorLines(target))
    this.canvas.on('object:scaling', ({ target }) => {
      this.updateObjectAnchorLines(target)
      this.updateShapeTextOnScale(target)
    })

    this.canvas.on('mouse:down:before', (opt) => this.onMouseDownBefore(opt))
    this.canvas.on('mouse:down', (opt) => this.onMouseDown(opt))
    this.canvas.on('mouse:up', (opt) => this.onMouseUp(opt))
    this.canvas.on('mouse:over', (opt) => this.onMouseOver(opt))
    this.canvas.on('mouse:out', (opt) => this.onMouseOut(opt))
    this.canvas.on('mouse:move', (opt) => this.onMouseMove(opt))
    this.canvas.on('mouse:wheel', (opt) => this.onMouseWheel(opt))
    this.canvas.on('mouse:dblclick', (opt) => this.emit('mouse:dblclick', opt))
    this.canvas.on('selection:created', (opt) => this.emit('selection:created', this.onSelectionChanged(opt)))
    this.canvas.on('selection:updated', (opt) => this.emit('selection:updated', this.onSelectionChanged(opt)))
    this.canvas.on('selection:cleared', (opt) => this.emit('selection:cleared', this.onSelectionChanged(opt)))

    // We're in free drawing mode by default
    this.setMode('free')

    this.panningData = null
    this.freehandArrows = { start: false, end: false }
    this.gridLines = []
  }

  resizeCanvas() {
    log.info('[wbengine] Resizing canvas')
    const outerSize = this.wrapperElement.getBoundingClientRect()
    this.canvasElement.width = outerSize.width
    this.canvasElement.height = outerSize.height

    if (this.canvas) {
      this.canvas.setWidth(outerSize.width)
      this.canvas.setHeight(outerSize.height)
      this.canvas.calcOffset()
    }
  }

  static generateLocalObjectId() {
    return 'L-' + Math.floor(Math.random() * 1000000000000).toString('36')
  }

  onSelectionChanged(event) {
    return {
      ...event,
      activeObject: this.canvas.getActiveObject(),
    }
  }

  onObjectAdded(target) {
    // ignore helper objects
    if (target.excludeFromExport) return

    // target object came from the network, no need to do anything
    if (target._id) return

    if (!target.localId) target.localId = CanvasWrapper.generateLocalObjectId()

    log.info(`[wbengine] User added ${target.type} object, localId = ${target.localId}`)
    this.emit('object:added', target.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
  }

  onObjectModified(target) {
    // ignore helper objects
    if (target.excludeFromExport) return

    const key = target._id || target.localId
    if (key) {
      // single object (even if the object is itself a group of basic shapes)
      log.info(`[wbengine] User modified object ${key}`)
      this.emit('object:modified', target.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
    } else if (target.getObjects !== undefined) {
      // selection
      this._onMultipleModified(target)
    } else if (!key && (target.type === "textbox" || target.type === "i-text")) {
      // if object is a textbox without an id, check if its parent has an id
      const parent = target.group
      if (parent._id) {
        this.emit('object:modified', parent.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
      }
    } else {
      log.error(`[wbengine] User modified objects we know nothing about, ignoring`, target)
    }
  }

  _onMultipleModified(group) {
    /* Multiple selection in Fabric is actually a new group. Adding object to a group
     * modifies its position on canvas to be relative to group, meaning we can't just
     * export them and send to server.
     *
     * Instead, we take all objects out of the current selection (which will force
     * recalculation of params to correct values), emit signals so data can be sent to
     * server, and then re-add to selection.
     */
    const groupObjects = group.getObjects()
    const objectIds = groupObjects.map((obj) => obj._id || obj.localId)
    log.info(`[wbengine] User modified ${objectIds.length} objects`)

    for (let obj of groupObjects) {
      group.remove(obj)
    }

    const canvasObjects = this.canvas.getObjects().filter((obj) => objectIds.includes(obj._id || obj.localId))
    for (let obj of canvasObjects) {
      this.emit('object:modified', obj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
    }

    for (let obj of canvasObjects) {
      group.add(obj)
    }
  }

  updateObjectAnchorLines(target) {
    if (target.isShapeContainer) {
      this.updateAnchoredLines(target)
    } else if (target._objects) { // Checking for shape objects in a group
      for (const object of target._objects) {
        if (object.isShapeContainer) {
          this.updateAnchoredLines(object)
        }
      }
    }
  }

  _getAnchorMapForObjects(objects) {
    return objects.reduce((acc, obj) => {
      const anchorGroup = obj._objects?.find((o) => o.isAnchorGroup)
      anchorGroup?.forEachObject((a) => acc[a.anchorId] = a)
      return acc
    }, {})
  }

  _updateLinesForAnchors(anchorMap) {
    const anchorIds = Object.keys(anchorMap)
    if (!anchorIds) {
      return
    }
    const lines = []
    this.canvas.getObjects().forEach((obj) => {
      let line = obj
      if (obj.isLineContainer) {
        line = obj._objects.find((o) => o.type === "line" || o.type === "path")
      }
      if (anchorIds.includes(line.anchorStart) || anchorIds.includes(line.anchorEnd)) {
        lines.push(line)
      }
    })
    const selection = this.canvas.getActiveObject()

    lines.forEach((line) => {
      const startAnchor = anchorMap[line.anchorStart]
      const endAnchor = anchorMap[line.anchorEnd]
      moveAnchoredLine(line, startAnchor, endAnchor, selection)
    })
  }

  updateAnchoredLines(shape) {
    const anchorMap = this._getAnchorMapForObjects([shape])
    this._updateLinesForAnchors(anchorMap)
  }

  updateShapeTextOnScale(target) {
    if (target.isShapeContainer || target.isLineContainer) {
      const textbox = target._objects.find((obj) => obj.type === "i-text" || obj.type === "textbox")

      if (textbox) {
        textbox.scaleX = Math.abs(1 / target.scaleX)
        textbox.scaleY = Math.abs(1 / target.scaleY)
        textbox.width = target.width * target.scaleX
        this.canvas.renderAll()
      }
    }
  }

  _dataToCanvasObject(data) {
    return new Promise((resolve, reject) => {
      const klass = fabric.classRegistry.getClass(data.type)
      if (!klass) {
        reject(new Error(`Cannot resolve object type ${data.type}`))
        return
      }
      klass.fromObject(data).then((obj) => {
        if (obj) {
          resolve(obj)
        } else {
          reject(new Error(`Cannot convert JSON data to object of type ${data.type}`))
        }
      })
    })
  }

  _removeFromSelection(ids) {
    const selection = this.canvas.getActiveObject()
    if (!selection) return

    // When a single object is currently selected
    const singleKey = selection._id || selection.localId
    if (singleKey) {
      if (ids.includes(singleKey)) {
        this.canvas.discardActiveObject()
      }
      return
    }

    if (selection.getObjects === undefined) {
      log.error(`[wbengine] Canvas selection is neither single object or group, ignoring`)
      return
    }

    // Multiple selection means a group of objects

    const toRemove = selection.getObjects().filter((obj) => {
      const key = obj._id || obj.localId
      return ids.includes(key)
    })

    for (let obj of toRemove) {
      selection.remove(obj)
    }
  }

  _replaceInSelection(oldObject, newObject) {
    const selection = this.canvas.getActiveObject()
    if (!selection) return

    // Case when a single object is selected
    if (selection._id || selection.localId) {
      if (selection === oldObject) {
        // This will deselect everything else
        this.canvas.setActiveObject(newObject)
      }
      return
    }

    if (selection.getObjects === undefined) {
      log.error(`[wbengine] Canvas selection is neither single object or group, ignoring`)
      return
    }

    // Multiple selection means a group of objects

    for (let obj of selection.getObjects()) {
      if (obj === oldObject) {
        selection.remove(obj)
        selection.add(newObject)
        return
      }
    }
  }

  async copySelection() {
    this._clipboard = await this.canvas.getActiveObject().clone(EXTRA_SERIALIZABLE_ATTRIBUTES)
  }

  async paste() {
    const randomAnchorIdOffset = Math.random().toString(16).substring(2)
    const clonedObj = await this._clipboard.clone(EXTRA_SERIALIZABLE_ATTRIBUTES)
    this.canvas.discardActiveObject()

    const pastedObjects = []
    if (clonedObj.type === 'activeselection') {
      clonedObj.forEachObject(async (obj) => {
        clonedObj.remove(obj)
        this._processCloneToPaste(obj, randomAnchorIdOffset)
        this.canvas.add(obj)
        pastedObjects.push(obj)
      })
      const newSelection = new fabric.ActiveSelection(pastedObjects, { canvas: this.canvas })
      this.canvas.setActiveObject(newSelection)
      this.canvas.requestRenderAll()
    } else {
      this._processCloneToPaste(clonedObj, randomAnchorIdOffset)
      this.canvas.add(clonedObj)
      this.canvas.setActiveObject(clonedObj)
    }
    this._clipboard.top += 10
    this._clipboard.left += 10
    this.canvas.requestRenderAll()
  }

  _processCloneToPaste(object, anchorIdOffset) {
    object.set({
      left: object.left + 10,
      top: object.top + 10,
      evented: true,
    })
    delete object._id
    if (object.anchorStart) {
      object.set('anchorStart', object.anchorStart + anchorIdOffset)
    }
    if (object.anchorEnd) {
      object.set('anchorEnd', object.anchorEnd + anchorIdOffset)
    }
    if (object.isShapeContainer && object._objects) {
      const nwObjAnchorGroup = object._objects.find((o) => o.isAnchorGroup)?._objects ?? []
      nwObjAnchorGroup.forEach((anchor) => anchor.set('anchorId', anchor.anchorId + anchorIdOffset))
    }

    return object
  }

  ungroupActiveObject() {
    const selection = this.canvas.getActiveObject()

    if (selection.type !== 'group') {
      log.error(`[wbengine] Selected object is not a group; cannot ungroup.`)
      return
    }

    const groupObjects = selection.getObjects()

    log.info(`[wbengine] User ungrouping ${groupObjects.length} objects`)

    // Remove each object from the group, then add it to the canvas as a new object.
    for (let obj of groupObjects) {
      selection.remove(obj)
      this.canvas.add(obj)
    }

    this.canvas.remove(selection)

    // Trigger removal of the (now empty) group on the backend.
    this.emit('object:removed', selection._id)

    return groupObjects
  }

  groupActiveSelection() {
    const selection = this.canvas.getActiveSelection()
    const objects = selection.getObjects()

    if (objects.length < 2) {
      log.error('[wbengine] Must specify more than one object to create a group; ignoring.')
      return
    }

    var newGroup = new fabric.Group(objects)
    this.canvas.add(newGroup)
    this.canvas.setActiveObject(newGroup)

    // Need to remove the group's source objects from the canvas and let listeners know that those objects were
    // removed from the canvas.
    objects.forEach((obj) => {
      this.canvas.remove(obj)
      this.emit('object:removed', obj._id)
      delete obj._id
      delete obj.localId
    })

    this.canvas.renderAll()
    return newGroup
  }

  async addObjects(objects, initial) {
    if (initial) {
      // FIXME - any local helper objects (not exportable from canvas) must be preserved
      // here
      await this.canvas.loadFromJSON({ objects })
      this.canvas.requestRenderAll()
      return this.canvas.getObjects()
    } else {
      // FIXME - any local helper objects (not exportable from canvas) must come after the
      // newly added objects so they're drawn on top of them

      const canvasObjects = []
      for (let obj of objects) {
        try {
          const canvasObj = await this._dataToCanvasObject(obj)
          canvasObjects.push(canvasObj)
        } catch (err) {
          log.info(`[wbengine] unable to convert ${obj.type} to Canvas object`, err)
        }
      }
      this.canvas.add(...canvasObjects)
      return canvasObjects
    }
  }

  async groupSeedObjects(objects) {
    const canvasObjects = []
    for (let obj of objects) {
      try {
        const canvasObj = await this._dataToCanvasObject(obj)
        canvasObjects.push(canvasObj)
      } catch (err) {
        log.info(`[wbengine] unable to convert ${obj.type} to Canvas object`, err)
      }
    }
    this.canvas.add(...canvasObjects)
    this.addToSelection(canvasObjects)
    this.setMode('select')
    return canvasObjects
  }

  getObjectById(id) {
    const objects = this.canvas.getObjects()
    for (let obj of objects) if (obj._id === id || obj.localId === id) return obj
    return null
  }

  removeObject(id) {
    const object = this.getObjectById(id)
    if (!object) throw new Error(`No object with id ${id}`)
    this._removeFromSelection([object._id || object.localId])
    this.canvas.remove(object)
  }

  removeMany(ids) {
    // Filter out objects that don't exist.
    const toRemove = ids.map((id) => this.getObjectById(id)).filter((obj) => obj !== null)
    if (!toRemove.length) return 0

    this._removeFromSelection(toRemove.map((obj) => obj._id || obj.localId))
    this.canvas.remove(...toRemove)
    return toRemove.length
  }

  removeAll() {
    this.canvas.remove(...this.canvas.getObjects())
  }

  async updateObject(data) {
    const objects = this.canvas.getObjects()
    const oldObject = this.getObjectById(data._id || data.localId)
    if (!oldObject) throw new Error(`No object with id ${data._id || data.localId}`)

    const index = objects.indexOf(oldObject)
    const newObject = await this._dataToCanvasObject(data)

    this.canvas.remove(oldObject)
    this.canvas.insertAt(index, newObject)
    this._replaceInSelection(oldObject, newObject)
    return newObject
  }

  /* Called on local objects after they were successfully pushed to server. Adds server-provided
   * properties (userId, time, _id) and removes localId as it's not needed any longer
   */
  updateObjectMeta(localId, { userId, time, _id }) {
    const object = this.getObjectById(localId)
    if (!object) {
      // Objects sent while offline are lost from the canvas upon reconnection.
      log.warn(`No object with local id ${localId}`)
      return false
    }

    object.userId = userId
    object.time = time
    object._id = _id
    delete object.localId

    log.info(`[wbengine] Updated local object ${localId} as remote object ${_id} by user ${userId} created at ${time}`)
    return true
  }

  get size() {
    return {
      width: this.canvas.width,
      height: this.canvas.height,
    }
  }

  get zoom() {
    return this.canvas.getZoom()
  }

  get viewportCenter() {
    return this.canvas.getVpCenter()
  }

  async _getCanvasWithWhiteBackground() {
    const clone = await this.canvas.clone()
    const g = new fabric.Group(clone.getObjects())
    const bbox = g.getBoundingRect()
    clone.setDimensions(bbox, { backstoreOnly: true })
    clone.absolutePan({ x: bbox.left, y: bbox.top })

    clone.backgroundColor = 'white'
    return clone
  }

  async getPNG() {
    const c = await this._getCanvasWithWhiteBackground()
    const image = c.toDataURL({ format: 'png' })
    const { width, height } = c
    c.dispose()
    return { image, width, height }
  }

  async getJPEG() {
    const c = await this._getCanvasWithWhiteBackground()
    const image = c.toDataURL({ format: 'jpeg' })
    const { width, height } = c
    c.dispose()
    return { image, width, height }
  }

  getSVG() {
    return this.canvas.toSVG()
  }

  setMode(mode) {
    if (!MODES.includes(mode)) {
      throw new Error(`Canvas mode must be one of: ${MODES.join(', ')}`)
    }

    this.mode = mode

    this.canvas.isDrawingMode = mode === 'free'
    this.canvas.skipTargetFind = mode !== 'select'
    this.canvas.selection = mode === 'select'

    // if mode === 'tool' or mode === 'pan', we're basically a static canvas

    if (mode === 'pan') {
      this.canvas.defaultCursor = 'grab'
      this.canvas.setCursor('grab')
    }
    else {
      this.canvas.defaultCursor = 'crosshair'
      this.canvas.setCursor('crosshair')
      this.canvas.hoverCursor = mode === 'select' ? 'move' : 'crosshair'
    }

    this.emit('mode:changed', mode)
  }

  setFreehandOptions(options) {
    if (this.mode !== 'free') return

    if (!this.canvas.freeDrawingBrush) {
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas)
    }
    if (options.strokeWidth) this.canvas.freeDrawingBrush.width = options.strokeWidth
    if (options.stroke) this.canvas.freeDrawingBrush.color = options.stroke
    if (options.startArrow !== undefined) this.freehandArrows.start = options.startArrow
    if (options.endArrow !== undefined) this.freehandArrows.end = options.endArrow
  }

  /* Get objects on canvas
   *
   * Returns an array of plain JavaScript objects, each representing one
   * object from the canvas. The returned objects are copies - modifying them
   * won't affect the actual objects on canvas.
   */
  getObjects() {
    return this.canvas
      .getObjects()
      .filter((obj) => !obj.excludeFromExport)
      .map((obj) => {
        return obj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES)
      })
  }

  /**
   * Get the active objects on the canvas. Optionally exclude objects being actively edited.
   *
   * @param {boolean} omitActiveEdits Do not include objects that are selected but being actively edited.
   * @returns [fabric object]
   */
  getSelectedObjects(omitActiveEdits) {
    return omitActiveEdits
      ? this.canvas.getActiveObjects().reduce((acc, obj) => {
          if (!obj.isEditing) {
            acc.push(obj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
          }
          return acc
        }, [])
      : this.canvas.getActiveObjects().map((obj) => obj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
  }

  onMouseDownBefore(opt) {
    if (this.mode !== 'pan') return

    /* If we're panning in freehand mode, we want to handle it as
     * soon as possible (hence :before) so that we can disable drawing
     * mode before its handlers get executed.
     */
    this.canvas.isDrawingMode = false
    this.canvas.skipTargetFind = true
    this.canvas.selection = false
    this.panningData = {
      x: opt.e.clientX,
      y: opt.e.clientY,
    }

    opt.e.preventDefault()
    opt.e.stopPropagation()
  }

  onMouseDown(opt) {
    if (this.panningData) return
    this.emit('event', 'mouse:down', this.canvas.getPointer(opt))
  }

  onMouseUp(opt) {
    if (!this.panningData) {
      this.emit('event', 'mouse:up', this.canvas.getPointer(opt))
      return
    }

    // force recalc interaction for all objects
    this.canvas.setViewportTransform(this.canvas.viewportTransform)
    this.panningData = null

    this.setMode(this.mode)
  }

  onMouseOver(opt) {
    this.emit('event', 'mouse:over', opt.target)
  }

  onMouseOut(opt) {
    this.emit('event', 'mouse:out', opt.target)
  }

  onMouseMove(opt) {
    if (!this.panningData) {
      this.emit('event', 'mouse:move', this.canvas.getPointer(opt))
      return
    }

    const ev = opt.e
    const vpt = this.canvas.viewportTransform

    vpt[4] += ev.clientX - this.panningData.x
    vpt[5] += ev.clientY - this.panningData.y
    this.canvas.renderAll()

    this.panningData = {
      x: ev.clientX,
      y: ev.clientY,
    }
  }

  onMouseWheel(opt) {
    const delta = opt.e.deltaY
    const currentZoom = this.canvas.getZoom()

    /* Different browsers/systems report wildly different deltas for scroll,
     * so we just ignore them (use just the direction/sign) and assume a
     * reasonable speed. */
    let zoom = currentZoom * 0.95 ** Math.sign(delta)
    if (Math.abs(zoom - 1.0) <= 0.02) {
      zoom = 1 // Fit back to 100% rather than having an unpleasant 99% or 101%
    }

    if (zoom > MAX_ZOOM) zoom = MAX_ZOOM
    if (zoom < MIN_ZOOM) zoom = MIN_ZOOM

    this.emit('event', 'panzoom:before')
    this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
    this.emit('event', 'panzoom')

    opt.e.preventDefault()
    opt.e.stopPropagation()
  }

  /* Transforms {x,y} point from absolute canvas coordinates (where in the canvas
   * the object is) to the current  viewport coordinates (where it is currently
   * being shown on the screen using the viewport transform
   */
  transformPoint(p) {
    const vpt = this.canvas.viewportTransform
    return fabric.util.transformPoint(p, vpt)
  }

  setZoom(scale) {
    this.emit('event', 'panzoom:before')
    this.canvas.zoomToPoint(this.viewportCenter, scale)
    this.emit('event', 'panzoom')
  }

  panTo(point) {
    // FIXME - this may have strange behaviour if zoom is not equal to 1, as
    // absolutePan requires after-zoom coordinates
    this.emit('event', 'panzoom:before')
    this.canvas.absolutePan(point)
    this.emit('event', 'panzoom')
  }

  /* Pans and zooms the canvas so all content is visible. The top/left
   * of the content bounding box will be placed at top/left of the
   * canvas viewport.
   */
  async zoomToFit() {
    const clone = await this.canvas.clone()
    const g = new fabric.Group(clone.getObjects().filter((object) => object.evented))
    const bbox = g.getBoundingRect()
    g.dispose()

    const topLeftCorner = { x: bbox.left, y: bbox.top }
    const scale = 1 / Math.max(bbox.width / this.canvas.width, bbox.height / this.canvas.height)

    /* Reset zoom before panning because absolutePan seems to want
     * after-zoom dimensions. After panning, we zoom in/out as needed,
     */
    this.emit('event', 'panzoom:before')
    this.setZoom(1)
    this.canvas.absolutePan(topLeftCorner)
    this.canvas.zoomToPoint({ x: 0, y: 0 }, scale)
    this.emit('event', 'panzoom')
  }

  toggleGrid(on) {
    var cellSize = 64
    var gridSize = 100
    var options = {
      fill: 'transparent',
      stroke: 'rgba(128, 128, 128, 0.7)',
      strokeWidth: 1,
      selectable: false,
      evented: false,
      excludeFromExport: true,
    }

    // Initializes the grid lines
    if (on && this.gridLines.length == 0) {
      // Create grid lines and add them to the canvas
      for (var i = -gridSize; i <= gridSize; i++) {
        var lineV = new fabric.Line(
          [i * cellSize, -gridSize * cellSize, i * cellSize, gridSize * cellSize],
          options
        )
        var lineH = new fabric.Line(
          [-gridSize * cellSize, i * cellSize, gridSize * cellSize, i * cellSize],
          options
        )

        this.gridLines.push(lineV)
        this.gridLines.push(lineH)
        this.canvas.add(lineV)
        this.canvas.add(lineH)
        this.canvas.sendObjectToBack(lineV)
        this.canvas.sendObjectToBack(lineH)
      }
    }

    this.gridLines.forEach((line) => {
      line.visible = on
    })
    this.canvas.renderAll()
  }

  /**
   * Clear the active selection on the canvas.
   */
  clearSelection() {
    this.canvas.discardActiveObject()
    this.canvas.renderAll()
  }

  /**
   * Update properties of the selection
   * If the selection is a group, propagate it to its objects
   */
  setOnSelection(updatedProperties) {
    const activeObj = this.canvas.getActiveObject()

    if (activeObj.type === 'activeselection') {
      activeObj.getObjects().forEach((o) => {
        if (o._id || o.localId) {
          this._setOnObject(o, { ...updatedProperties })
        }
      })
      this._onMultipleModified(activeObj)
    } else if (activeObj._id || activeObj.localId) {
      this._setOnObject(activeObj, updatedProperties)
      this.emit('object:modified', activeObj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
    } else {
      this._setOnObject(activeObj, updatedProperties)
    }
    this.canvas.renderAll()
  }

  _setOnObject(object, updatedProperties) {
    // We need to be able to edit fill color and opacity independently, so we take them in as
    // special non-standard properties and determine the final fill value per object in the selection
    const fillColor = updatedProperties.fillColor
    const fillOpacity = updatedProperties.fillOpacity
    // .padStart is used so small values e.g. 'f' get formatted as '0f'
    const fillOpacityHex = fillOpacity !== undefined
      ? Math.floor(fillOpacity * 255).toString(16).padStart(2, '0')
      : undefined
    delete updatedProperties.fillColor
    delete updatedProperties.fillOpacity

    let selectedShape
    if (object.isShapeContainer || object.isLineContainer) {
      selectedShape = object.getObjects()[0]
    } else {
      selectedShape = object
    }

    if (fillColor || fillOpacity) {
      const shapeFill = selectedShape.fill
      const updatedColor = fillColor || shapeFill?.slice(0, 7)  // #FFFFFF--
      const updatedOpacity = fillOpacityHex || shapeFill?.slice(7, 9) || 'FF' // -------FF
      if (updatedColor === 'transparent') {
        updatedProperties.fill = ''
      } else if (updatedColor) {
        updatedProperties.fill = `${updatedColor}${updatedOpacity}`
      }
    }

    if (selectedShape.type === 'group') {
      selectedShape.getObjects().forEach((subObject) => {
        const newProperties = { ...updatedProperties }
        if (subObject.lockStroke) {
          delete newProperties.stroke
        }
        if (subObject.lockFill) {
          delete newProperties.fill
        }
        subObject.set(newProperties)
      })
    }
    // we want to also set it on the group, so that we can easily tell on the frontend what color/etc the whole shape is
    selectedShape.set(updatedProperties)
  }

  bringSelectionToFront() {
    const activeObj = this.canvas.getActiveObject()
     // moving an entire selection at once introduces weird behavior, so only allow a single target
     if (activeObj.type === "activeselection") {
      return
    }
    this.canvas.bringObjectToFront(activeObj)
    this.emit('object:modified', activeObj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
    this.canvas.renderAll()
  }

  sendSelectionToBack() {
    const activeObj = this.canvas.getActiveObject()
    // moving an entire selection at once introduces weird behavior, so only allow a single target
    if (activeObj.type === "activeselection") {
      return
    }
    this.canvas.sendObjectToBack(activeObj)
    this.emit('object:modified', activeObj.toObject(EXTRA_SERIALIZABLE_ATTRIBUTES))
    this.canvas.renderAll()
  }

  /**
   * Add a collection of board objects to the active selection, if present. Otherwise create a new selection.
   *
   * @param {Array[obj]} board objects to add.
   */
  addToSelection(objs) {
    const activeObj = this.canvas.getActiveObject()
    if (activeObj && activeObj.type === 'activeselection') {
      activeObj.add(...objs)
    } else {
      this.clearSelection()
      const sel = new fabric.ActiveSelection(objs, { canvas: this.canvas })
      this.canvas.setActiveObject(sel)
      this.canvas.requestRenderAll()
    }
  }
}

export default CanvasWrapper
