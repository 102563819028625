import * as fabric from 'fabric'

const ERASER_SIZE = 40
const ERASER_COLOR = '#e8e6e6'
const ERASER_STROKE_SIZE = 4
const ERASER_STROKE_COLOR = '#adadad'
const ERASER_OPACITY = 0.65

class Eraser {
  constructor(wb) {
    this.wb = wb

    this.handleErasingStart = this.handleErasingStart.bind(this)
    this.handleErasingEnd = this.handleErasingEnd.bind(this)
  }

  get canvas() {
    return this.wb.canvasWrapper.canvas
  }

  handle(eventName, data) {
    switch (eventName) {
      case 'erasing:start': {
        // No-op for now. Perhaps there is something creative we can do later?
        break
      }
      case 'erasing:end': {
        // On erasing:end, grab the targets of the erase and fire object:modified events for each target object.
        // This will cause the CanvasWrapper to fire the appropriate events that will cause the target objects to
        // be updated with their new clip paths on the backend.
        data.targets.forEach((target) => {
          this.canvas.fire('object:modified', { target })
        })
        break
      }
    }
  }

  // Callback to hit the handle method with erase start data. This is mostly to satisfy the pattern of having a
  // `handle` method that processes events.
  handleErasingStart(data) {
    this.handle('erasing:start', data)
  }

  // Callback to hit the handle method with erase end data. This is mostly to satisfy the pattern of having a
  // `handle` method that processes events.
  handleErasingEnd(data) {
    this.handle('erasing:end', data)
  }

  dispose() {
    // Restore the previous drawing brush for the canvas.
    this.canvas.freeDrawingBrush = this.prevDrawingBrush
    this.canvas.freeDrawingCursor = this.prevDrawingCursor

    // Remove the EraserBrush event callbacks.
    this.canvas.off('erasing:start', this.handleErasingStart)
    this.canvas.off('erasing:end', this.handleErasingEnd)
  }

  getDrawCursor() {
    const viewBoxDim = (ERASER_SIZE + ERASER_STROKE_SIZE) * 2

    const circle = `
      <svg
        height="${ERASER_SIZE}"
        fill="${ERASER_COLOR}"
        viewBox="0 0 ${viewBoxDim} ${viewBoxDim}"
        width="${ERASER_SIZE}"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50%"
          cy="50%"
          r="${ERASER_SIZE}"
          opacity="${ERASER_OPACITY}"
          stroke="${ERASER_STROKE_COLOR}"
          stroke-width="${ERASER_STROKE_SIZE}"
        />
      </svg>
    `

    return `data:image/svg+xml;base64,${btoa(circle)}`
  }

  setCanvasOptions() {
    // Persist the current drawing brush. This will be restored when the eraser is disposed of.
    this.prevDrawingBrush = this.canvas.freeDrawingBrush
    this.prevDrawingCursor = this.canvas.freeDrawingCursor

    // Replace the canvas' drawing brush with an EraserBrush.
    this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas)
    this.canvas.freeDrawingBrush.width = ERASER_SIZE
    this.canvas.isDrawingMode = true

    this.canvas.freeDrawingCursor = `url(${this.getDrawCursor()}) ${ERASER_SIZE / 2} ${ERASER_SIZE / 2}, crosshair`

    this.wb.canvasWrapper.freehandArrows.start = false
    this.wb.canvasWrapper.freehandArrows.end = false

    // Add callbacks for the EraserBrush events.
    this.canvas.on('erasing:start', this.handleErasingStart)
    this.canvas.on('erasing:end', this.handleErasingEnd)
  }
}

export default Eraser
