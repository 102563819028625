import * as fabric from 'fabric'
import { io } from 'socket.io-client'
import log from 'loglevel'

import CanvasWrapper from './CanvasWrapper'
import EventEmitter from './EventEmitter'
import SocketClient from './SocketClient'
import SandboxSocketClient from './SandboxSocketClient'
import UndoStack from './UndoStack'
import UserPointers from './UserPointers'

import builtinTools from './tools'

const REQUIRED_OPTIONS = ['canvasElement']

export class WhiteboardEngine extends EventEmitter {
  constructor(options = {}) {
    super()

    this.options = this._parseOptions(options)

    // If the `sandbox` option is specified, use the SandboxSocketClient to avoid connecting to the WBEngine backend,
    // but still be able to use the WBEngine client as a drawing canvas.
    this.socketClient = options.sandbox ? new SandboxSocketClient() : new SocketClient(this.options.socket)
    this.socketClient.on('connect', () => this.emit('connect'))
    this.socketClient.on('disconnect', () => this.emit('disconnect'))
    this.socketClient.on('connect_error', () => this.emit('connect_error'))
    this.socketClient.on('board:objects', (objects, initial) => this.onBoardObjects(objects, initial))
    this.socketClient.on('board:objects:seed', (objects) => this.onBoardObjectsSeedGroup(objects))
    this.socketClient.on('board:clear', () => this.onBoardClear())
    this.socketClient.on('object:update', (object) => this.onObjectUpdate(object))
    this.socketClient.on('object:remove', (objectId) => this.onObjectRemove(objectId))
    this.socketClient.on('user:list', (users) => this.onUserList(users))
    this.socketClient.on('user:connect', (userId, users) => this.onUserConnect(userId, users))
    this.socketClient.on('user:disconnect', (userId, users) => this.onUserDisconnect(userId, users))
    this.socketClient.on('user:change', (userId, userData) => this.onUserChange(userId, userData))

    this.canvasWrapper = new CanvasWrapper(options.canvasElement)
    this.canvasWrapper.on('object:added', (object) => this.onObjectAdded(object))
    this.canvasWrapper.on('object:modified', (object) => this.onObjectModified(object))
    this.canvasWrapper.on('object:removed', (objectId) => this.onCanvasObjectRemoved(objectId))
    this.canvasWrapper.on('event', (name, options) => this.onCanvasEvent(name, options))
    this.canvasWrapper.on('mouse:dblclick', ({ target }) => {
      // Only allow editing shape text while in `select` mode.
      if (this.canvasWrapper.mode === 'select' && target && target.hasEditableText) {
        const textbox = target._objects.find((o) => o.type === "textbox" || o.type === "i-text")
        if (textbox) {
          this.canvasWrapper.canvas.setActiveObject(textbox)
          textbox.enterEditing()
        }
      }
    })
    this.canvasWrapper.on('selection:created', (data) => this.emit('selection:created', data))
    this.canvasWrapper.on('selection:updated', (data) => this.emit('selection:updated', data))
    this.canvasWrapper.on('selection:cleared', (data) => this.emit('selection:cleared', data))
    this.canvasWrapper.on('mode:changed', (data) => this.emit('mode:changed', data))

    this.undoStack = new UndoStack(this.canvasWrapper)
    this.undoStack.on('undo:change', (data) => this.emit('undo:change', data))

    /* Lists IDs of objects we're currently sending to server, as effect of
     * adding or modifying it locally. This is so we can detect any local updates
     * on them before the server response arrives. Each ID is either a remote-supplied
     * _id (for objects that are known to server) or localId for new objects we still
     * didn't push to the server. */
    this.objectsInFlight = {}

    /* Map of _id -> object for all objects that were modified locally while
     * they were being sent to the server. After response from the server, we
     * usually want to re-send the updates to the server knows the latest state.
     */
    this.modifiedQueue = {}

    if (this.options.boardId && this.options.userId) {
      this.connect(this.options.boardId, this.options.userId)
    }

    this.installedTools = { ...builtinTools }
    this.tool = null
    this.toolOptions = {}
    this.gridIsOn = false
    this.bgColor = null

    this.users = {}

    if (this.options.userPointers) {
      this.userPointers = new UserPointers(this.socketClient, this.canvasWrapper, this.options.userPointers)
    } else {
      this.userPointers = null
    }

    this.LOG_LEVELS = log.levels
    log.setDefaultLevel(log.levels.ERROR)
  }

  _parseOptions(options) {
    for (let opt of REQUIRED_OPTIONS) {
      if (!(opt in options)) throw new Error(`${opt} is a required option`)
    }
    return options
  }

  get isConnected() {
    return this.socketClient.isConnected
  }

  connect(boardId, userId, userData) {
    this.options.boardId = boardId
    this.options.userId = userId
    this.options.userData = userData
    if (this.userPointers) {
      this.userPointers.setUserId(userId)
    }

    return this.socketClient.connect(boardId, userId, userData)
  }

  disconnect() {
    return this.socketClient.disconnect()
  }

  // Called on incoming remote objects
  async onBoardObjects(objects, initial) {
    const addedObjects = await this.canvasWrapper.addObjects(objects, initial)
    this.emit("objects:added", addedObjects)

    if (initial) {
      this.undoStack.resetState()
    } else {
      this.undoStack.recordState(false)
    }
  }

  // Called on incoming remote objects - seeded as a selection
  async onBoardObjectsSeedGroup(objects) {
    await this.canvasWrapper.groupSeedObjects(objects)
  }

  // Called when object was updated remotely
  async onObjectUpdate(object) {
    await this.canvasWrapper.updateObject(object)

    /* If it was also updated locally, we can discard any
     * queued local modifications.
     *
     * Note: casual readers may wonder what happens if
     * the object is updated remotely while it's being pushed
     * (ie. we have no _id for it yet), since here we're matching by _id.
     * Rest assured: if the object was remotely modified, this means that
     * server already knows about it, meaning we've already successfully
     * pushed it and have an _id for it.
     */
    const localObj = this.modifiedQueue[object._id]
    if (localObj) {
      log.info(`[wbengine] Removing queued modification of ${object._id}, it was modified remotely`)
      delete this.modifiedQueue[object._id]
    }
  }

  // Called when object was deleted remotely
  async onObjectRemove(id) {
    // If it's waiting to be updated on our side, forget it
    delete this.modifiedQueue[id]
    return this.canvasWrapper.removeObject(id)
  }

  async onCanvasObjectRemoved(id) {
    try {
      await this.socketClient.removeObject(id)
    } catch (err) {
      log.error(`[wbengine] Error removing object ${id}:`, err)
    }
  }

  /* Called after push/modify finishes, to check if there are any
   * pending modifications that happened in the meantime and if so,
   * reruns the modification.
   *
   * If a local object was locally modified while being sent to the
   * server via pushObjects, the locally updated version will not
   * have the server-provided userId, time and _id objects, so we
   * need to manually update those so they're correctly sent to the
   * server (actualy canvas object already has these updated via
   * call to canvasWrapper.updateMetaData() from onObjectAdded).
   *
   * In case of a push of a local object, `id` will actually be `localId`.
   * In case a modification of already-saved object is being done,
   * `id`` will be `_id`.
   */
  async _processModifiedQueue(id, response = null) {
    let updatedVersion = this.modifiedQueue[id]
    if (!updatedVersion) return

    delete this.modifiedQueue[id]

    if (updatedVersion.localId) {
      /* Local object, need to update meta-data. Only case when this would
       * happen is if we were doing a push of new object, in which case
       * we should have a response from the server here. If not, it's a
       * logical bug */
      if (response) {
        const { userId, time, _id } = response
        updatedVersion = { ...updatedVersion, userId, time, _id }
      } else {
        log.error(`[wbengine] _processModifiedQueue(): processing queued local object but there's no server response?`)
      }
      delete updatedVersion.localId
    }

    log.info(`[wbengine] Sending queued update for object ${id}`)
    this.onObjectModified(updatedVersion)
  }

  /* Called when an object was added locally
   *
   * In this case, the object has a locally-generated id (localId), which
   * is used as the idenfier until it is pushed to server and gets a
   * server-assigned id (_id).
   *
   * Since the push process may be slow, we'll have to queue any local
   * modifications user may do while it is in progress and then process
   * the queue once our push operation is done.
   */
  async onObjectAdded(object) {
    const localId = object.localId

    /* Only want to push this change down the stack if it's NOT a
     * result of undo or redo operation, in which case it'll have
     * a brand new local ID.
     */
    const fromUndoRedo = this.undoStack.containsObject(localId)
    this.undoStack.recordState(!fromUndoRedo)

    try {
      this.objectsInFlight[localId] = true

      const response = await this.socketClient.pushObject(object)
      if (this.canvasWrapper.updateObjectMeta(localId, response)) {
        this.undoStack.assignId(localId, response._id)
      } else {
        // When offline, any inflight objects are lost upon reconnection as they
        // haven't been recorded to the board, so they aren't present in the
        // reconnection's full object list. Re-add them for continuity.
        object.userId = response.userId
        object.time = response.time
        object._id = response._id
        delete object.localId
        await this.addObjects([object])
      }

      delete this.objectsInFlight[localId]
      this._processModifiedQueue(localId, response)

      this.emit('object:added', object)
    } catch (err) {
      log.error(`[wbengine] Error adding object, rolling back`, err)
      this.canvasWrapper.removeObject(localId)
      this.undoStack.resetState()

      delete this.objectsInFlight[localId]
      // If we abandoned addition of object locally, no need to update the
      // server with new data on it, even if it was updated in the meantime
      delete this.modifiedQueue[localId]

      this.undoStack.resetState()
    }
  }

  // Called when an object was modified locally
  async onObjectModified(object, skipStack = false) {
    this.emit('object:modified', object)
    /* For pushed objects we use _id, but for local objects where we don't
     * have it, we fallback to localId. These are namespaced so there's no
     * chance of collision.
     */
    const key = object._id || object.localId

    this.undoStack.recordState(!skipStack)

    /* If we're already in the process of pushing object/changes to it,
     * we'll just queue the new version and rerun the modification after
     * the current operation on it has finished.
     */
    if (this.objectsInFlight[key]) {
      log.info(`[wbengine] Modified in-flight object, queuing change`)
      this.modifiedQueue[key] = object
      return
    }

    try {
      this.objectsInFlight[key] = true

      const response = await this.socketClient.modifyObject(object)

      delete this.objectsInFlight[key]
      this._processModifiedQueue(key, response)
    } catch (err) {
      log.error(`[wbengine] Error modifying object ${key}`, err)
      delete this.objectsInFlight[key]
      this.undoStack.resetState()

      /* Even if update failed, since the server already knows about the object,
       * it makes sense to resend any pending update on the same object. If successful,
       * that one will also fix the inconsistency caused by this update failing.
       */
      this._processModifiedQueue(key)
    }
  }

  async getPNG() {
    const { image } = await this.canvasWrapper.getPNG()
    return image
  }

  async getJPEG() {
    const { image } = await this.canvasWrapper.getJPEG()
    return image
  }

  getSVG() {
    return this.canvasWrapper.getSVG()
  }

  restorePrevMode() {
    if (this.prevMode) {
      this.setMode(this.prevMode)
    }
  }

  setMode(mode) {
    if (this.currentMode) {
      this.prevMode = this.currentMode
    }
    this.currentMode = mode
    const m = mode.match(/^tool:(.+)/)
    if (m) {
      const toolName = m[1]
      const tool = this.installedTools[toolName]
      if (!tool) throw new Error(`Unknown tool ${toolName}`)
      mode = 'tool'

      if (this.tool) this.tool.dispose()
      this.tool = new tool(this)
    } else {
      if (this.tool) this.tool.dispose()
      this.tool = null
    }
    this.canvasWrapper.setMode(mode)
    if (mode !== "select") {
      this.canvasWrapper.clearSelection()
    }

    if (this.tool && this.tool.setCanvasOptions) {
      this.tool.setCanvasOptions()
    }

    if (mode === 'free') {
      this.canvasWrapper.setFreehandOptions(this.toolOptions)
    }
  }

  setToolOptions(options) {
    this.toolOptions = options
    if (this.canvasWrapper.mode === 'free') {
      this.canvasWrapper.setFreehandOptions(options)
    }
  }

  get selectedObjects() {
    return this.canvasWrapper.getSelectedObjects()
  }

  getSelectionObject() {
    return this.canvasWrapper.canvas.getActiveObject()
  }

  async copySelection() {
    await this.canvasWrapper.copySelection()
  }

  paste() {
    this.canvasWrapper.paste()
  }

  groupSelectedObjects() {
    return this.canvasWrapper.groupActiveSelection()
  }

  ungroupSelectedGroup() {
    this.canvasWrapper.ungroupActiveObject()
  }

  async removeObjects(ids, skipStack = false) {
    if (!ids.length) return

    // FIXME - handle case where the objects to remove weren't pushed to
    // the server yet, and figure out what happens if the push completes
    // after the remove?
    const nRemoved = this.canvasWrapper.removeMany(ids)

    /* This can happen if we're undoing object creation (thus need to remove),
     * but the object was previously removed by another user
     */
    if (!nRemoved) {
      log.info(`[wbengine] Nothing to remove, object(s) not in canvas any more`)
      return
    }

    this.undoStack.recordState(!skipStack)

    for (let id of ids) {
      try {
        await this.socketClient.removeObject(id)
      } catch (err) {
        log.error(`[wbengine] Error removing objects ${ids.join(', ')}:`, err)
        // FIXME - okay so we removed them locally but server refused to remove?
        // OR where they nonexistent on server so it's a nop?
      }
    }
  }

  async removeSelectedObjects() {
    const selected = this.canvasWrapper.getSelectedObjects(true)
    if (!selected.length) return

    const ids = selected.map((obj) => obj._id || obj.localId)
    log.info(`[wbengine] Removing ${ids.length} selected object(s)`)
    this.removeObjects(ids)
  }

  async removeAllObjects() {
    const objs = this.canvasWrapper.getObjects()

    if (!objs.length) return

    const ids = objs.map((obj) => obj._id || obj.localId)
    log.info(`[wbengine] Removing all objects`)
    this.removeObjects(ids)
  }

  async addObjects(objects) {
    await this.canvasWrapper.addObjects(objects, false)
  }

  onCanvasEvent(name, options) {
    if (this.tool) {
      this.tool.handle(name, options)
      return
    }

    if (name === 'key' && (options.key === 'Delete' || options.key === 'Backspace')) {
      this.removeSelectedObjects()
      return
    }

    this.emit(name, options)
  }

  /* This is used only internally, for modifying objects as part of undo/redo,
   * so we're never recording stack state */
  async _modifyObjects(objects) {
    for (let obj of objects) {
      try {
        await this.canvasWrapper.updateObject(obj)

        /* We want to fire all of updates in parallel and we don't need the results here,
         * so no need to await. */
        this.onObjectModified(obj, true)
      } catch (err) {
        // FIXME - analyze code paths to see if this is even possible - if so, likely as a bug
        // where undo stack object ids are not updated on deletion
        log.info(`[wbengine] Trying to modify object ${obj._id || obj.localId} which is not in canvas, ignoring`)
      }
    }
  }

  // Add/Remove/Modify objects as neccessary as part of undo/redo operation
  async _processUndoRedoChanges(todo) {
    todo.add.forEach((obj) => {
      if (obj._id) {
        /* We're undoing deletion of an object that was pushed to the server
         * already, meaning deletion was pushed as well. We're resurrecting it
         * as a new object with new local ID and need to update the undo stack
         * so any other actions related to the old object now point to the new
         * one.
         *
         * If the object wasn't pushed yet, it still has a localId that never
         * changed so we can continue using that and don't need to update the
         * undo stack.
         */
        obj.localId = CanvasWrapper.generateLocalObjectId()
        this.undoStack.propagateLocalId(obj._id, obj.localId)
        delete obj._id
      }

      // Mark this object as a result of undo or redo operation, so that a new
      // state doesn't get pushed as the result of its addition
      obj._resultOfUndoRedo = true
    })

    await this.addObjects(todo.add)
    await this._modifyObjects(todo.modify)
    await this.removeObjects(
      todo.delete.map((obj) => obj._id || obj.localId),
      true
    )
  }

  undo() {
    const todo = this.undoStack.undo()
    if (!todo) {
      log.info(`[wbengine] Ignoring undo request, nothing to undo`)
      return
    }

    return this._processUndoRedoChanges(todo)
  }

  async redo() {
    const todo = this.undoStack.redo()
    if (!todo) {
      log.info(`[wbengine] Ignoring redo request, nothing to redo`)
      return
    }

    return this._processUndoRedoChanges(todo)
  }

  async addImageFromURL(url) {
    const imageTool = new this.installedTools.image(this)
    let img
    try {
      img = await imageTool.addFromURL(url)
    } catch {
      log.info(`[wbengine] Could not add image URL.`, url)
    }
    return img
  }

  updateUserData(data) {
    this.socketClient.updateUserData(data)
  }

  onUserList(users) {
    const present = Object.keys(users)
      .filter((userId) => users[userId].present)
      .map((userId) => users[userId].name || '(anonymous)')
    log.info(`[wbengine] ${present.length} user(s) present (${Object.keys(users).length} total):`, present)

    this.users = users
    this.emit('user:list', users)
  }

  onUserConnect(userId, users) {
    log.info(`[wbengine] User ${userId} connected`, users[userId])
    this.users = users
    this.emit('user:connect', userId, users)
  }

  onUserDisconnect(userId, users) {
    log.info(`[wbengine] User ${userId} disconnected`, users[userId])
    this.users = users
    this.emit('user:disconnect', userId, users)
  }

  onUserChange(userId, userData) {
    log.info(`[wbengine] User ${userId} data changed`, userData)
    this.users[userId] = userData
    this.emit('user:change', userId, userData)
  }

  onBoardClear() {
    log.info(`[wbengine] board cleared`)
    this.canvasWrapper.removeAll()
    this.emit('board:cleared')
  }

  get zoom() {
    return this.canvasWrapper.zoom
  }

  setZoom(scale) {
    return this.canvasWrapper.setZoom(scale)
  }

  panTo(x, y) {
    return this.canvasWrapper.panTo({ x, y })
  }

  zoomToFit() {
    if (!this.canvasWrapper.getObjects().length) return

    return this.canvasWrapper.zoomToFit()
  }

  toggleGrid() {
    this.gridIsOn = !this.gridIsOn
    this.canvasWrapper.toggleGrid(this.gridIsOn)
    return this.gridIsOn
  }

  clearSelection() {
    this.canvasWrapper.clearSelection()
  }

  addToSelection(objs) {
    this.canvasWrapper.addToSelection(objs)
  }

  /* Updates the currently selected shape.
   * Requires the current selection contain only one object,
   * and that object must be a polyline or a group containing a polyline object
   */
  setOnSelection(updatedProperties) {
    this.canvasWrapper.setOnSelection(updatedProperties)
  }

  bringSelectionToFront() {
    this.canvasWrapper.bringSelectionToFront()
  }

  sendSelectionToBack() {
    this.canvasWrapper.sendSelectionToBack()
  }

  /* Resize the whiteboard to match the current size of
   * the wrapper element.
   *
   * Can be used in window.onresize handler or whenever you
   * change the page layout, to adapt the board size to the
   * new layout/size.
   */
  resize() {
    this.canvasWrapper.resizeCanvas()
  }

  /* Exposes FabricJS directly to the user */
  get fabric() {
    return fabric
  }

  /* Exposes Socket.IO directly to the user
   *
   * The only valid use for this if you want to establish
   * other independent socket connections and don't want to
   * load socket.io-client code twice.
   */
  get io() {
    return io
  }

  /* Returns current socket connection
   *
   * Use with care to avoid turning your codebase into pasta.
   * In most cases, refactoring SocketClient will be better
   * answer than adding behaviour outside SocketClient
   * or WhiteboardEngine.
   */
  get socket() {
    return this.socketClient.socket
  }

  /* Set logging level
   *
   * Level can be one of "silent" (nothing is shown),
   * "trace", "debug", "info", "warn" or "error". The list of
   * log levels and their numerical equivalents is available in
   * the "LOG_LEVELS" attribute of this object.
   *
   * The log level is persisted through in localStorage or JS cookie.
   * The default log level, if not configured, is "error" (suitable
   * for production use).
   */
  set logLevel(level) {
    return log.setLevel(level)
  }

  /* Get current log level as a string.
   */
  get logLevel() {
    const level = log.getLevel()
    for (let l in this.LOG_LEVELS) {
      if (this.LOG_LEVELS[l] === level) return l.toLowerCase()
    }
    return level
  }
}

export default WhiteboardEngine
