import * as fabric from 'fabric'

import { ShapeWithEditableText } from './ShapeWithEditableText'

class Ellipse extends ShapeWithEditableText {
  isShapeOfType(canvasObj) {
    return canvasObj.hasEditableText && canvasObj.getObjects().find((o) => o.type === 'ellipse')
  }

  getAnchorPointsPositions() {
    const nbPoints = 8
    const points = []
    for (let i = 0; i < nbPoints; i++) {
      const angle = i * 2 * Math.PI / nbPoints
      points.push({ x: (Math.cos(angle) + 1) / 2, y: (Math.sin(angle) + 1) / 2 })
    }
    return points
  }

  buildHelperShape(x, y) {
    return new fabric.Ellipse({
      top: y,
      left: x,
      rx: 0,
      ry: 0,
      fill: '',
      strokeWidth: 2,
      stroke: this.options.stroke,
      strokeDashArray: [5, 5],
      excludeFromExport: true,
    })
  }

  getHelperMoveOptions({ x, y }) {
    return {
      top: Math.min(y, this.start.y),
      left: Math.min(x, this.start.x),
      rx: Math.abs(x - this.start.x) / 2,
      ry: Math.abs(y - this.start.y) / 2,
    }
  }

  buildFinalShape() {
    const { top, left, rx, ry } = this.helper

    return new fabric.Ellipse({
      top,
      left,
      rx,
      ry,
      strokeWidth: this.options.strokeWidth || 2,
      stroke: this.options.stroke || '',
      fill: this.buildFillColor(),
      shapeType: 'ellipse',
      strokeUniform: true,
    })
  }
}

export default Ellipse
