import * as fabric from 'fabric'
import { appendArrows } from './arrows'
import { getAbsoluteCenterPosition, getDistanceSquared } from '../util'

const DEFAULT_FONT_SIZE = 40
const ANCHOR_MIN_DIST = 30
const ANCHOR_RADIUS = 5
const ANCHOR_SVG_STRING =
'<svg viewBox="0 0 24 24" data-testid="AnchorIcon" fill="{color}">' +
  '<path d="m17 15 1.55 1.55c-.96 1.69-3.33 3.04-5.55 3.37V11h3V9h-3V7.82C14.16 7.4 15 6.3 15 5c0-1.65-1.35-3-3-3S9 3.35 9 5c0 1.3.84 2.4 2 2.82V9H8v2h3v8.92c-2.22-.33-4.59-1.68-5.55-3.37L7 15l-4-3v3c0 3.88 4.92 7 9 7s9-3.12 9-7v-3l-4 3zM12 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z"></path>' +
'</svg>'
const ANCHOR_ICON_MARGIN = 10
const LINE_START_CLICK_DISTANCE_THRESHOLD = 5

class Line {
  constructor(wb) {
    this.wb = wb
    this.helper = null
    this.hoveredShape = null
    this.previousShapeSelectable = null
    this.anchor = null
    this.anchorMarker = null
    this.anchorIcon = null
    this.anchorPoints = null
    this.setAllAnchorsPoints()
  }

  get canvas() {
    return this.wb.canvasWrapper.canvas
  }

  get options() {
    return this.wb.toolOptions || {}
  }

  setAllAnchorsPoints() {
    this.anchorPoints = []
    const shapes = this.canvas.getObjects().filter((obj) => obj.shapeType)

    for (const shape of shapes) {
      const shapeObjects = shape.getObjects()
      const shapeAnchorPoints = shapeObjects.find((o) => o.isAnchorGroup)?.getObjects() ?? []
      for (const anchorPoint of shapeAnchorPoints) {
        anchorPoint.absolutePosition = getAbsoluteCenterPosition(anchorPoint)
        this.anchorPoints.push(anchorPoint)
      }
    }
  }

  handle(eventName, data) {
    switch (eventName) {
      case 'mouse:up':
        this.onMouseUp(data)
        break
      case 'mouse:move':
        this.onMouseMove(data)
        break
      case 'mouse:down':
        this.onMouseDown(data)
        break
      case 'mouse:over':
        this.onMouseOver(data)
        break
      case 'mouse:out':
        this.onMouseOut(data)
        break
      default:
        // If not handled by this tool, let WhiteboardEngine emit the event.
        this.wb.emit(eventName, data)
    }
  }

  dispose() {
    this._disposeHelper()
    this._disposeAnchor()
    this.canvas.perPixelTargetFind = this.prevPerPixelTargetFind
    this.canvas.skipTargetFind = this.prevCanvasSkipTargetFind
  }

  _disposeHelper() {
    if (this.helper) {
      this.canvas.remove(this.helper)
      this.helper = null
    }
  }

  _disposeAnchor() {
    if (this.anchor) {
      this.canvas.remove(this.anchorMarker)
      this.canvas.remove(this.anchorIcon)
      this.anchorMarker = null
      this.anchorIcon = null
      this.anchor = null
      this.canvas.renderAll()
    }
  }

  _createLineObject(points, opts) {
    if (opts.excludeFromExport) {
      // We can't easily reposition Path and have the arrows look good, so
      // helper is always an ordinary line.
      return new fabric.Line(points, opts)
    }

    if (!this.options.startArrow && !this.options.endArrow) {
      return new fabric.Line(points, opts)
    }

    const [x1, y1, x2, y2] = points
    const path = `M0,0 L${x2 - x1},${y2 - y1}`

    const pathWithArrows = appendArrows(
      path,
      this.options.startArrow,
      this.options.endArrow,
      opts.strokeWidth * 4 // arrow length is "4 strokeWidths"
    )

    return new fabric.Path(pathWithArrows, {
      ...opts,
      strokeLineCap: 'round',
      top: Math.min(y1, y2),
      left: Math.min(x1, x2)
    })
  }

  onMouseDown({ x, y }) {
    if (this.helper) return

    if (this.anchor) {
      x = this.anchor.absolutePosition.x
      y = this.anchor.absolutePosition.y
    }

    this.helper = this._createLineObject([x, y, x, y], {
      strokeWidth: this.options.strokeWidth || 2,
      strokeLineCap: 'round',
      stroke: this.options.stroke || 'black',
      excludeFromExport: true,
      anchorStart: this.anchor
    })
    this.canvas.add(this.helper)
  }

  onMouseOver(object) {
    if (!object) {
      return
    }
    this.hoveredShape = object
    // Prevents moving the shape when clicking on it
    this.previousShapeSelectable = this.hoveredShape.selectable
    this.hoveredShape.selectable = false
  }

  onMouseOut() {
    if (this.hoveredShape) {
      this.hoveredShape.selectable = this.previousShapeSelectable
      this.hoveredShape = null
    }
  }

  onMouseMove({ x, y }) {
    const lineEnd = this.handleMoveAnchor(x, y)
    if (!this.helper) return
    this.helper.set({ x2: lineEnd.x, y2: lineEnd.y })
    this.canvas.renderAll()
  }

  onMouseUp() {
    if (!this.helper) return

    let { x1, y1, x2, y2, anchorStart } = this.helper

    if (this.anchor) {
      x2 = this.anchor.absolutePosition.x
      y2 = this.anchor.absolutePosition.y
    }

    // if the start and end points of the line are the same, then it was a mouse click
    // event, rather than a mouse drag event.  in that case, we want the next mouse click
    // to represent the termination of the line
    if (getDistanceSquared(x1, y1, x2, y2) < LINE_START_CLICK_DISTANCE_THRESHOLD) return

    const locks = anchorStart || this.anchor ? {
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
    } : {}

    const line = this._createLineObject(
      [x1, y1, x2, y2],
      {
        strokeWidth: this.options.strokeWidth || 2,
        strokeLineCap: 'round',
        stroke: this.options.stroke || 'black',
        perPixelTargetFind: true,
        ...locks
      },
    )

    const editableTextFontSize = this.wb.toolOptions.fontSize || DEFAULT_FONT_SIZE
    const textbox = new fabric.Textbox('', {
      // Important to note that the textbox is center-origin'ed. This precludes needing to reposition the textbox
      // following an edit to the text in the shape.
      originX: 'center',
      originY: 'center',
      top: y1 + (y2 - y1) / 2,
      left: x1 + (x2 - x1) / 2,
      width: x2 - x1,
      textAlign: 'center',
      fontSize: editableTextFontSize,
      fontFamily: this.options.fontFamily || 'sans-serif',
      stroke: this.options.fontColor || this.options.stroke || 'black',
      fill: this.options.fontColor || this.options.stroke || 'black',
      fontWeight: this.options.fontWeight || 'normal',
      fontStyle: this.options.fontStyle || 'normal',
      underline: this.options.underline,
      linethrough: this.options.linethrough,
      objectCaching: false,
    })

    this.addAnchors(line, anchorStart, this.anchor, { x1, y1, x2, y2 })

    const g = new fabric.Group(
      [line, textbox],
      {
        subTargetCheck: true,
        hasEditableText: true,
        shapeType: 'line',
        isLineContainer: true,
        ...locks,
      },
    )

    this.canvas.add(g)
    this._disposeHelper()
    this.wb.setMode('select')
  }

  // Returns the current mouse { x, y } if no anchor in sight, else the anchor { x, y }
  handleMoveAnchor(x, y) {
    let closestAnchorPoint = null
    let minDistSq = ANCHOR_MIN_DIST * ANCHOR_MIN_DIST
    for (const anchorPoint of this.anchorPoints) {
      const distSq = getDistanceSquared(x, y, anchorPoint.absolutePosition.x, anchorPoint.absolutePosition.y)
      if (distSq < minDistSq) {
        minDistSq = distSq
        closestAnchorPoint = anchorPoint
      }
    }

    if (closestAnchorPoint) {
      const ax = closestAnchorPoint.absolutePosition.x
      const ay = closestAnchorPoint.absolutePosition.y
      this.moveAnchor({ x: ax, y: ay })
      this.anchor = closestAnchorPoint
      return { x: ax, y: ay }
    }
    else {
      this._disposeAnchor()
    }
    return { x, y }
  }

  addAnchors(line, anchorStart, anchorEnd, points) {
    if (!anchorStart && !anchorEnd) {
      return
    }
    line.absolutePoints = points
    line.anchorStart = anchorStart?.anchorId
    line.anchorEnd = anchorEnd?.anchorId
  }

  setCanvasOptions() {
    // Keep track of the previous canvas perPixelTargetFind and skipTargetFindProperies.
    this.prevPerPixelTargetFind = this.canvas.perPixelTargetFind
    this.prevCanvasSkipTargetFind = this.canvas.skipTargetFind

    // Overwrite the canvas perPixelTargetFind and skipTargetFind properties so that we can detect objects hovering
    this.canvas.perPixelTargetFind = true
    this.canvas.skipTargetFind = false
  }

  async moveAnchor({ x, y }) {
    if (this.anchorMarker == null) {
      this.anchorMarker = new fabric.Circle({
        originX: 'center',
        originY: 'center',
        radius: ANCHOR_RADIUS,
        fill: '#FFF',
        stroke: '#000',
        strokeWidth: 1,
        excludeFromExport: true,
        objectCaching: false,
        evented: false,
      })
      this.canvas.add(this.anchorMarker)
    }
    this.anchorMarker.set({ top: y, left: x })

    if (!this.anchorIcon) {
      const anchorSvgString = ANCHOR_SVG_STRING.replace('{color}', "#ccc") // TODO: should depend on light/dark mode

      const { objects, options } = await fabric.loadSVGFromString(anchorSvgString)
      if (this.anchorIcon) {
        this.canvas.remove(this.anchorIcon)
      }
      this.anchorIcon = fabric.util.groupSVGElements(objects, options)
      this.anchorIcon.set({ left: x + ANCHOR_ICON_MARGIN, top: y - ANCHOR_ICON_MARGIN - this.anchorIcon.height, excludeFromExport: true })
      this.canvas.add(this.anchorIcon)
    }
    else {
      this.anchorIcon.set({ left: x - ANCHOR_ICON_MARGIN, top: y - ANCHOR_ICON_MARGIN - this.anchorIcon.height })
    }

    this.canvas.renderAll()
  }
}

export default Line
