import * as fabric from 'fabric'

const DEFAULT_FONT_SIZE = 40

/**
 * Abstract class to implement a shape with editable text. Implementations extending ShapeWithEditableText need to
 * override certain methods related to the specific shape they are intended to render.
 */
export class ShapeWithEditableText {
  constructor(wb) {
    this.wb = wb
    this.helper = null
  }

  /**
   * Returns boolean indicating that a shape is one that should be text-edited. For example:
   * ```
   * canvasObj.hasEditableText && canvasObj.getObjects().find((o) => o.type === 'ellipse')
   * ```
   */
  isShapeOfType(canvasObj) {
    throw new Error('Must be implemented by subclass')
  }

  /**
   * Returns a Fabric shape that will be the "helper" shape while the shape is being drawn.
   */
  buildHelperShape(x, y) {
    throw new Error('Must be implemented by subclass')
  }

  /**
   * Returns a Fabric shape that will be added to a group with the textbox shape.
   */
  buildFinalShape() {
    throw new Error('Must be implemented by subclass')
  }

  /**
   * Returns options that will be set on the helper as the mouse cursor changes position.
   *
   * @param {fabric#Point} point Current point the mouse cursor is at.
   */
  getHelperMoveOptions(point) {
    throw new Error('Must be implemented by subclass')
  }

  /**
   * Returns a list of anchors points as { x, y } relative to width and height
   * For example, [{ x: 1, y: 1 }, { x: 0.5, y: 0.5 }] would mean the bottom-right corner & center of the shape as anchor points
   *
   * @param {float} widthRatio Optional, the ratio width/height
   */
  getAnchorPointsPositions(widthRatio) {
    throw new Error('Must be implemented by subclass')
  }

  get canvas() {
    return this.wb.canvasWrapper.canvas
  }

  get options() {
    return this.wb.toolOptions || {}
  }

  handle(eventName, data) {
    switch (eventName) {
      case 'mouse:up':
        this.onMouseUp(data)
        break
      case 'mouse:move':
        this.onMouseMove(data)
        break
      case 'mouse:down':
        // If a shape of this type with editable text is selected, then enter text editing mode on that shape.
        const selectedObjs = this.wb.selectedObjects
        const selectedCanvasObj =
          selectedObjs && selectedObjs.length === 1 && this.wb.canvasWrapper.getObjectById(selectedObjs[0]._id)
        // If an object was selected, lock it's movement so that it won't move with as the mouse cursor moves to
        // draw the shape. Make sure that the object's movement gets restored when the drawing is done.
        if (selectedCanvasObj) {
          this.objectMovementToRestore = selectedCanvasObj
          selectedCanvasObj.lockMovementX = true
          selectedCanvasObj.lockMovementY = true
        }
        // TODO undo selection canvas property?
        this.canvas.selection = false

        // Otherwise start drawing a new shape.
        this.wb.clearSelection()
        this.onMouseDown(data)
        break
      default:
        // If not handled by this tool, let WhiteboardEngine emit the event.
        this.wb.emit(eventName, data)
    }
  }

  dispose() {
    this._disposeHelper()
    this.canvas.selection = this.prevCanvasSelection
    this.canvas.skipTargetFind = this.prevCanvasSkipTargetFind
  }

  _disposeHelper() {
    if (this.helper) {
      this.canvas.remove(this.helper)
      this.helper = null

      this.canvas.selection = true
    }
  }

  onMouseDown({ x, y }) {
    if (this.helper) return

    this.helper = this.buildHelperShape(x, y)
    this.start = { x, y }
    this.canvas.add(this.helper)
  }

  onMouseMove(point) {
    if (!this.helper) return

    const helperMoveOptions = this.getHelperMoveOptions(point)

    // case when the helper is a group
    if (helperMoveOptions.objects != null) {
      this.helper._objects = helperMoveOptions.objects
      this.helper.trueWidth = helperMoveOptions.trueWidth
      this.helper.trueHeight = helperMoveOptions.trueHeight
    } else {
      this.helper.set(helperMoveOptions)
    }

    this.helper.setCoords()
    this.canvas.requestRenderAll()
  }

  onMouseUp({ x, y }) {
    if (!this.helper) return

    let { top, left, width, height, trueWidth, trueHeight } = this.helper
    // When drawing polygons, we may need to get the actual bounding box dimensions
    width = trueWidth ?? width
    height = trueHeight ?? height

    // Only draw the shape if it has some dimension, abort and discard the helper shape otherwise.
    if (!width || !height) {
      this._disposeHelper()
      return
    }

    const finalShape = this.buildFinalShape({x, y})

    const editableTextFontSize = this.wb.toolOptions.fontSize || DEFAULT_FONT_SIZE
    const textbox = new fabric.Textbox('', {
      // Important to note that the textbox is center-origin'ed. This precludes needing to reposition the textbox
      // following an edit to the text in the shape.
      originX: 'center',
      originY: 'center',
      top: top + height / 2,
      left: left + width / 2 + 1,
      width,
      textAlign: 'center',
      fontSize: editableTextFontSize,
      fontFamily: this.options.fontFamily || 'sans-serif',
      stroke: this.options.fontColor || this.options.stroke,
      fill: this.options.fontColor || this.options.stroke,
      fontWeight: this.options.fontWeight || 'normal',
      fontStyle: this.options.fontStyle || 'normal',
      underline: this.options.underline,
      linethrough: this.options.linethrough,
      objectCaching: false,
    })

    const anchorPointPositions = this.getAnchorPointsPositions(height !== 0 ? width / height : 1)
    const anchorPoints = new fabric.Group(
      anchorPointPositions.map((position) => this.buildAnchorPoint(top, left, height, width, position, finalShape.shapeType)),
      {
        isAnchorGroup: true,
      }
    )

    // Instead of adding just the shape, go ahead and set up a group with the shape and an empty text box. This will
    // be useful later when editing the text in the textbox.
    const g = new fabric.Group(
      [
        finalShape,
        textbox,
        anchorPoints,
      ],
      {
        subTargetCheck: true,
        hasEditableText: true,
        shapeType: finalShape.shapeType,
        isShapeContainer: true,
      },
    )
    this.canvas.add(g)
    this._disposeHelper()

    // Restore the movement of, if present, an object that was selected on mouse down to start drawing a shape.
    if (this.objectMovementToRestore) {
      this.objectMovementToRestore.lockMovementX = false
      this.objectMovementToRestore.lockMovementY = false

      this.objectMovementToRestore = null
    }

    this.wb.setMode('select')
  }

  buildAnchorPoint(top, left, height, width, { x, y }, shapeType) {
    const correction = shapeType === 'cylinder' ? { dx: 4, dy: 4 } : { dx: 1, dy: 1 } // For some reason the cylinder has some shape bounding box discrepancies
    return new fabric.Circle({
      top: top + y * height + correction.dy,
      left: left + x * width + correction.dx,
      originX: 'center',
      originY: 'center',
      radius: 0,
      anchorId: Math.random().toString(16).substring(2)
    })
  }

  setCanvasOptions() {
    // Keep track of the previous canvas selection and skipTargetFindProperies.
    this.prevCanvasSelection = this.canvas.selection
    this.prevCanvasSkipTargetFind = this.canvas.skipTargetFind

    // Overwrite the canvas selection and skipTargetFind properties so that we can select an object whose text to edit.
    this.canvas.selection = true
    this.canvas.skipTargetFind = false
  }

  buildFillColor() {
    let fillColor = this.options.fill || ''

    if (fillColor === 'transparent') {
      return ''
    }

    if (this.options.fillOpacity) {
      // .padStart is used so small values e.g. 'f' get formatted as '0f'
      const fillOpacityHex = Math.floor(this.options.fillOpacity * 255).toString(16).padStart(2, '0')
      fillColor = `${fillColor}${fillOpacityHex}`
    }
    return fillColor
  }
}
