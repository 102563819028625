import * as fabric from 'fabric'

import { ShapeWithEditableText } from './ShapeWithEditableText'

class Rect extends ShapeWithEditableText {
  isShapeOfType(canvasObj) {
    return canvasObj.hasEditableText && canvasObj.getObjects().find((o) => o.type === 'rect')
  }

  getAnchorPointsPositions() {
    return [
      // Corners
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 0, y: 1 },
      { x: 1, y: 1 },
      // Middles
      { x: 0.5, y: 0 },
      { x: 0.5, y: 1 },
      { x: 0, y: 0.5 },
      { x: 1, y: 0.5 },
      // Quarters
      { x: 0.25, y: 0 },
      { x: 0.75, y: 0 },
      { x: 0.25, y: 1 },
      { x: 0.75, y: 1 },
      { x: 0, y: 0.25 },
      { x: 0, y: 0.75 },
      { x: 1, y: 0.25 },
      { x: 1, y: 0.75 },
    ]
  }

  buildHelperShape(x, y) {
    return new fabric.Rect({
      top: y,
      left: x,
      width: 0,
      height: 0,
      fill: '',
      strokeWidth: 2,
      stroke: this.options.stroke,
      strokeDashArray: [5, 5],
      excludeFromExport: true,
    })
  }

  getHelperMoveOptions({ x, y }) {
    return {
      top: Math.min(y, this.start.y),
      left: Math.min(x, this.start.x),
      width: Math.abs(x - this.start.x),
      height: Math.abs(y - this.start.y),
    }
  }

  buildFinalShape() {
    const { top, left, width, height } = this.helper

    return new fabric.Rect({
      top,
      left,
      width,
      height,
      strokeWidth: this.options.strokeWidth || 2,
      stroke: this.options.stroke || '',
      fill: this.buildFillColor(),
      shapeType: 'rect',
      strokeUniform: true,
    })
  }
}

export default Rect
