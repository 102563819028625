import * as fabric from 'fabric'

import { ShapeWithEditableText } from './ShapeWithEditableText'

const ECCENTRICITY = 2.0

class Cylinder extends ShapeWithEditableText {

  isShapeOfType(canvasObj) {
    return canvasObj.hasEditableText && canvasObj.getObjects().find((o) => o.shapeType === 'cylinder')
  }

  getAnchorPointsPositions(widthRatio) {
    const ellipseSemiMinorAxis = widthRatio / (2 * ECCENTRICITY)
    const quarterEllipseHeight = ellipseSemiMinorAxis * (1 - (1 - 1 / Math.sqrt(2)) / ECCENTRICITY)
    return [
       // Borders
       { x: 0, y: 0 },
       { x: 0, y: 0.25 },
       { x: 0, y: 0.5 },
       { x: 0, y: 0.75 },
       { x: 0, y: 1 },
       { x: 1, y: 0 },
       { x: 1, y: 0.25 },
       { x: 1, y: 0.5 },
       { x: 1, y: 0.75 },
       { x: 1, y: 1 },
       // Tops
       { x: 0.5, y: -ellipseSemiMinorAxis },
       { x: 0.25, y: -quarterEllipseHeight },
       { x: 0.75, y: -quarterEllipseHeight },
       { x: 0.5, y: 1 + ellipseSemiMinorAxis },
       { x: 0.25, y: 1 + quarterEllipseHeight },
       { x: 0.75, y: 1 + quarterEllipseHeight },
    ]
  }


  _getHelperOptions() { 
    return {
      fill: '',
      strokeWidth: 2,
      stroke: this.options.stroke,
      strokeDashArray: [5, 5],
      excludeFromExport: true,
      objectCaching: false,
    }
  }

  _buildObjects(x, y, width, height, options) {
    const rx = width / 2.0
    const ry = rx / ECCENTRICITY
    const sw = options.strokeWidth ?? 2
    const ribClip = new fabric.Rect({ top: 0, left: -width / 2 - sw, width: width + 2 * sw, height: ry + sw })
    const bgClip = new fabric.Group([
      new fabric.Ellipse({ top: -height / 2 - ry, left: -width / 2, rx, ry }),
      new fabric.Rect({ top: -height / 2, left: -width / 2, width: width, height }),
      new fabric.Ellipse({ top: height / 2 - ry, left: -width / 2, rx, ry }),
    ])
    return [
      // background
      new fabric.Rect({ top: y - ry - sw, left: x - sw, width: width + 2 * sw, height: height + 2 * ry + 2 * sw, ...options, stroke: '', lockStroke: true, clipPath: bgClip }),
      // shape lines
      new fabric.Ellipse({ top: y + height - ry, left: x, rx, ry, ...options, fill: '', lockFill: true, clipPath: ribClip, strokeUniform: true }),
      new fabric.Ellipse({ top: y + height * (2 / 3) - ry, left: x, rx, ry, ...options, fill: '', lockFill: true, clipPath: ribClip, strokeUniform: true }),
      new fabric.Ellipse({ top: y + height * (1 / 3) - ry, left: x, rx, ry, ...options, fill: '', lockFill: true, clipPath: ribClip, strokeUniform: true }),
      new fabric.Line([x + (sw / 2), y, x + (sw / 2), y + height + sw], { ...options, strokeUniform: true }),
      new fabric.Line([x + (sw / 2) + width, y, x + (sw / 2) + width, y + height + sw], { ...options, strokeUniform: true }),
      new fabric.Ellipse({ top: y - ry, left: x, rx, ry, ...options, fill: '', lockFill: true, strokeUniform: true }),
    ]
  }

  buildHelperShape(x, y) {
    return new fabric.Group(this._buildObjects(x, y, 0, 0, this._getHelperOptions()), this._getHelperOptions())
  }


  getHelperMoveOptions({ x, y }) {
    // Coords of the bounding box
    const top = Math.min(y, this.start.y)
    const left = Math.min(x, this.start.x)
    const width = Math.abs(x - this.start.x)
    const height = Math.abs(y - this.start.y)
    const helper = new fabric.Group(this._buildObjects(left, top, width, height, this._getHelperOptions()), this._getHelperOptions())
    return {
      objects: helper._objects,
      trueWidth: x - this.start.x,
      trueHeight: y - this.start.y,
    }
  }

  buildFinalShape({ x, y }) {
    // Coords of the bounding box
    const top = Math.min(y, this.start.y)
    const left = Math.min(x, this.start.x)
    const width = Math.abs(x - this.start.x)
    const height = Math.abs(y - this.start.y)
    const options = {
      strokeWidth: this.options.strokeWidth || 2,
      stroke: this.options.stroke || '',
      fill: this.buildFillColor(),
      shapeType: 'cylinder'
    }

    return new fabric.Group(
      this._buildObjects(left, top, width, height, options), options
    )
  }
}

export default Cylinder
