import Line from './line'
import Rect from './rect'
import Diamond from './diamond'
import Cylinder from './cylinder'
import Ellipse from './ellipse'
import Text from './text'
import Image from './image'
import Eraser from './eraser'
import ObjectDeleter from './objectDeleter'

export default {
  line: Line,
  rect: Rect,
  diamond: Diamond,
  cylinder: Cylinder,
  ellipse: Ellipse,
  text: Text,
  image: Image,
  eraser: Eraser,
  objectDeleter: ObjectDeleter,
}
