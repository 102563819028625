import * as fabric from 'fabric'

class ObjectDeleter {
  constructor(wb) {
    this.wb = wb
    this.isSelecting = false
  }

  get canvas() {
    return this.wb.canvasWrapper.canvas
  }

  handle(eventName, data) {
    switch (eventName) {
      case 'mouse:down': {
        this.isSelecting = true
        // Since the ObjectDeleter tool is making use of canvas selection, prevent moving the object(s) being selected.
        this.wb.selectedObjects.forEach((obj) => {
          const canvasObj = this.wb.canvasWrapper.getObjectById(obj._id)
          canvasObj.lockMovementX = true
          canvasObj.lockMovementY = true
        })
        break
      }
      case 'mouse:up': {
        this.wb.removeSelectedObjects()
        this.isSelecting = false
        break
      }
    }
  }

  dispose() {
    this.canvas.skipTargetFind = this.prevCanvasSkipTargetFind
    this.canvas.selection = this.prevCanvasSelection
  }

  setCanvasOptions() {
    this.prevCanvasSkipTargetFind = this.canvas.skipTargetFind
    this.prevCanvasSelection = this.canvas.selection

    this.canvas.skipTargetFind = false
    this.canvas.selection = true
  }
}

export default ObjectDeleter
