import * as fabric from 'fabric'

import { ShapeWithEditableText } from './ShapeWithEditableText'

class Diamond extends ShapeWithEditableText {
  isShapeOfType(canvasObj) {
    return canvasObj.hasEditableText && canvasObj.getObjects().find((o) => o.shapeType === 'diamond')
  }

  getAnchorPointsPositions() {
    return [
      // Corners
      { x: 0.5, y: 0 },
      { x: 0.5, y: 1 },
      { x: 0, y: 0.5 },
      { x: 1, y: 0.5 },
    ]
  }

  buildHelperShape(x, y) {
    return new fabric.Polyline([
      { x: x, y: y },
    ],
    { 
      fill: '',
      strokeWidth: 2,
      stroke: this.options.stroke,
      strokeDashArray: [5, 5],
      excludeFromExport: true,
      objectCaching: false,
    })
  }

  getHelperMoveOptions({ x, y }) {
    // Coords of the bounding box
    const top = Math.min(y, this.start.y)
    const left = Math.min(x, this.start.x)
    const width = Math.abs(x - this.start.x)
    const height = Math.abs(y - this.start.y)
    return {
      points: [
        { x: left + width / 2.0,  y: top },
        { x: left + width,        y: top + height / 2.0 },
        { x: left + width / 2.0,  y: top + height },
        { x: left,                y: top + height / 2.0 },
        { x: left + width / 2.0,  y: top },
      ],
      trueWidth: x - this.start.x,
      trueHeight: y - this.start.y,
    }
  }

  buildFinalShape() {
    return new fabric.Polyline(
      this.helper.points,
      {
        strokeWidth: this.options.strokeWidth || 2,
        stroke: this.options.stroke || '',
        fill: this.buildFillColor(),
        shapeType: 'diamond',
        strokeUniform: true,
      },
    )
  }
}

export default Diamond
