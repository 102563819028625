import * as fabric from 'fabric'
import log from 'loglevel'

/* Helpers to add beginning or ending arrows to any Fabric path
 */

/* Extract the ending point of path segment. Expects simplified Fabric path
 * (absolute coordinates) and works on M, L and Q commands. Returns the
 * endpoint as [x, y] array.
 */
const pointFromSegment = (seg) => {
  if (seg[0] === 'Q') {
    return [seg[3], seg[4]]
  } else if (seg[0] === 'M' || seg[0] === 'L') {
    return [seg[1], seg[2]]
  } else {
    log.error(`[wbengine] Unsupported SVG path segment ${seg[0]} encountered `)
    return [0, 0]
  }
}

/* Given points a and b given as [x,y] coordinate arrays, returns
 * angle of the a → b vector
 */
const vectorAngle = (a, b) => {
  const [x1, y1] = a
  const [x2, y2] = b

  return Math.atan2(x2 - x1, y1 - y2)
}

// Calculates and returns path length and the start and end points/angles
function getEndingVectors(path) {
  path = fabric.util.makePathSimpler(fabric.util.parsePath(path))
  if (!path || path.length < 2) return { length: 0 }

  // Length of path in pixels is calculated by summing segment lengths
  const length = fabric.util
    .getPathSegmentsInfo(path)
    .map((seg) => seg.length)
    .reduce((sum, l) => sum + l, 0)

  const start = pointFromSegment(path[0])
  const start2 = pointFromSegment(path[1])
  const end = pointFromSegment(path[path.length - 1])
  const end2 = pointFromSegment(path[path.length - 2])

  return {
    length,
    start,
    end,
    // angles point away from the line
    startAngle: vectorAngle(start2, start),
    endAngle: vectorAngle(end2, end),
  }
}

/* Generate SVG path for line arrow/ending, given origin point
 * (where the line starts/ends) and angle of the vector pointing
 * in the direction of the line, away from it.
 *
 * Origin point is given as [x,y] coordinate array and the angle
 * is given in radians. Arrow length is "length" of the arrow,
 * in this case length of the side lines comprising the arrow.
 *
 * Must return a SVG path string.
 */
function generateArrowPath(origin, angle, arrowLen) {
  const [x, y] = origin
  angle = angle + Math.PI / 2
  const dy1 = arrowLen * Math.sin(angle + Math.PI / 10)
  const dx1 = arrowLen * Math.cos(angle + Math.PI / 10)
  const dy2 = arrowLen * Math.sin(angle - Math.PI / 10)
  const dx2 = arrowLen * Math.cos(angle - Math.PI / 10)

  return [`M${x},${y}`, `l${dx1},${dy1}`, `M${x},${y}`, `l${dx2},${dy2}`].join(' ')
}

/* Takes a string SVG path and appends start and/or end
 * arrow to it. The arrows are only appended if the path length
 * is at least one arrow length.
 *
 * Returns the new path as string.
 */
export function appendArrows(path, startArrow, endArrow, arrowLen) {
  if (!startArrow && !endArrow) return path

  const v = getEndingVectors(path)
  if (!v.length || v.length < arrowLen) return path

  if (startArrow) {
    path = path + ' ' + generateArrowPath(v.start, v.startAngle, arrowLen)
  }

  if (endArrow) {
    path = path + ' ' + generateArrowPath(v.end, v.endAngle, arrowLen)
  }

  return path
}
