import log from 'loglevel'

class EventEmitter {
  constructor() {
    this._eventEmitterListeners = {}
  }

  on(event, callback) {
    const listeners = this._eventEmitterListeners

    if (!(event in listeners)) {
      listeners[event] = []
    }
    listeners[event].push(callback)
  }

  once(event, callback) {
    const onceHandler = (...args) => {
      this.off(event, onceHandler)
      callback(...args)
    }
    this.on(event, onceHandler)
  }

  off(event, callback) {
    const listeners = this._eventEmitterListeners
    if (!(event in listeners)) return

    listeners[event] = listeners[event].filter((cb) => cb !== callback)
  }

  emit(event, ...args) {
    const callbacks = this._eventEmitterListeners[event]
    if (!callbacks) return

    for (let cb of callbacks) {
      try {
        cb(...args)
      } catch (err) {
        log.error('Uncaught exception in event callback:', err)
      }
    }
  }
}

export default EventEmitter
