import log from 'loglevel'

import EventEmitter from './EventEmitter'

/* Create an ID => object map of objects, where
 * ID is either permanent _id or localId */
const makeMap = (objects) => {
  const map = {}
  for (let obj of objects) {
    map[obj._id || obj.localId] = obj
  }
  return map
}

/* Do a deep comparison of "a" and "b", returning
 * true if they're equal. Not as exact as node's
 * assert.deepEqual, but sufficient for our purposes.
 */
const deepEqual = (a, b) => {
  const typeA = typeof a

  if (typeA !== typeof b) return false
  if (a === null) return b === null
  if (a === undefined) return b === undefined

  // arrays
  if (a.forEach && a.length !== undefined) {
    if (!b.forEach || a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) return false
    }
    return true
  }

  if (typeA === 'object') {
    for (let key in a) {
      if (!deepEqual(a[key], b[key])) return false
    }
    return true
  }

  return a === b
}

class UndoStack extends EventEmitter {
  constructor(canvasWrapper) {
    super()
    this.canvasWrapper = canvasWrapper
    this.resetState()
  }

  /* Record current state of the Fabric canvas, including all
   * current objects. Should be called after every modification
   * of canvas, so we can calculate the delta on modifications we
   * care about.
   *
   * If this is a modification we care about (so, something not
   * resulting out of undo/redo or change from outside), we also
   * compute and store the delta.
   */
  recordState(recordChange) {
    const objects = this.canvasWrapper.getObjects()

    if (this.currentState && recordChange) {
      const action = this._computeChange(objects)

      if (action.count === 0) {
        return
      }

      /* If we undoed some actions, after we record this one we
       * can't redo them, ie. we need to trim the undo stack up
       * to the current depth level.
       */
      if (this.depth) {
        this.stack = this.stack.slice(this.stack.length - this.depth)
        this.depth = 0
      }

      this.stack.push(action)
      log.info(`[wbengine] Pushing change to undo stack, now ${this.stack.length} levels deep`, action)
      this._emitChangeEvent()
    }
    this.currentState = objects
  }

  /* Computes change between the new objects and last recorded
   * state ("current State"). It is assumed that the object id
   * does not change (see caveat below). Objects that exist
   * in both states are deep-compared to check for any changes.
   *
   * Caveat: when new local push is confirmed on the server, we
   * delete "localId" and assign "_id". This will break this
   * algorithm. For that reason, recordState MUST be called at
   * that point WITHOUT recording a change, just to update the
   * current state with the ID change.
   */
  _computeChange(objects) {
    const oldMap = makeMap(this.currentState)
    const newMap = makeMap(objects)

    const action = {
      added: [],
      modified: [],
      deleted: [],
      count: 0,
    }

    for (let obj of objects) {
      const key = obj._id || obj.localId
      const oldObj = oldMap[key]

      if (oldObj) {
        // filter out anchor line modifications, those will resolve on their own when
        // their associated objects fire their modify event
        if (oldObj.anchorStart || oldObj.anchorEnd) continue
        if (!deepEqual(obj, oldObj)) {
          action.modified.push({
            new: obj,
            old: oldObj,
          })
          action.count += 1
        }
      } else {
        action.added.push({
          new: obj,
        })
        action.count += 1
      }
    }

    for (let obj of this.currentState) {
      if (!newMap[obj._id || obj.localId]) {
        action.deleted.push({
          old: obj,
        })
        action.count += 1
      }
    }

    return action
  }

  /* Called when something happens that screws up our
   * history, such as server returning error from our
   * push (create/modify). In that case it's too hard
   * to reconstruct correct undo stack with the rejected
   * operation removed, so it's easier to just start
   * from scratch.
   *
   * Since this only happens on weird edge cases such as
   * server errors, this shouldn't affect usability by
   * a lot.
   */
  resetState() {
    log.info(`[wbengine] Resetting undo stack to empty state`)
    this.currentState = null
    this.stack = []
    this.depth = 0
    this.recordState(false)
    this._emitChangeEvent()
  }

  assignId(localId, id) {
    log.info(`[wbengine] Undo stack: assign ${id} to local object ${localId}`)

    for (let obj of this.currentState) {
      if (obj.localId === localId) {
        delete obj.localId
        obj._id = id
      }
    }

    /* If anywhere on the current undo stack there are actions that add, modify or delete
     * the item just being re-added as part of undo/redo, we want to update
     * the actions' item ID so it properly refers to the newly created item. */
    for (let entry of this.stack) {
      const updateItemId = (item) => {
        if (item.old) {
          item.old._id = id
          delete item.old.localId
        }

        if (item.new) {
          item.new._id = id
          delete item.new.localId
        }
      }
      entry.modified.filter((item) => item.old.localId === localId).forEach(updateItemId)
      entry.deleted.filter((item) => item.old.localId === localId).forEach(updateItemId)
      entry.added.filter((item) => item.new.localId == localId).forEach(updateItemId)
    }
  }

  /* Propagates newly created local ID for a resurrected object
   * (as part of undo or redo operation) throughout the stack so
   * other actions on this item (modifications, deletion) refer
   * to the new object correctly.
   *
   * Matches both _id and localId for the old ID and always sets
   * localId and clears _id, since it's called immediately before
   * creating the said object, at which point it doesn't have new
   * server-assigned _id.
   */
  propagateLocalId(oldId, newLocalId) {
    log.info(`[wbengine] Undo stack: propagating object ID change: ${oldId} → ${newLocalId}`)

    /* Since change item can have either old or new objects or both, and
     * ID can be either local or global, we need to check in all places.
     */
    const getKey = (item) => {
      const obj = item.old || item.new
      return obj._id || obj.localId
    }

    const processItems = (items) => {
      for (let item of items) {
        // We're only interested in matching items
        const itemKey = getKey(item)
        if (itemKey !== oldId) continue

        if (item.old) {
          delete item.old._id
          item.old.localId = newLocalId
        }

        if (item.new) {
          delete item.new._id
          item.new.localId = newLocalId
        }
      }
    }

    for (let entry of this.stack) {
      processItems(entry.added)
      processItems(entry.modified)
      processItems(entry.deleted)
    }
  }

  containsObject(localId) {
    const isIn = (items) =>
      items.some((item) => (item.old && item.old.localId === localId) || (item.new && item.new.localId === localId))

    for (let entry of this.stack) {
      if (isIn(entry.added) || isIn(entry.modified) || isIn(entry.deleted)) return true
    }
    return false
  }

  undo() {
    if (!this.canUndo) return null

    const targetAction = this.stack[this.stack.length - this.depth - 1]

    const requiredChanges = {
      add: targetAction.deleted.map((item) => item.old),
      modify: targetAction.modified.map((item) => item.old),
      delete: targetAction.added.map((item) => item.new),
    }

    this.depth = this.depth + 1

    this._emitChangeEvent()
    return requiredChanges
  }

  redo() {
    if (!this.canRedo) return null

    this.depth = this.depth - 1
    const targetAction = this.stack[this.stack.length - this.depth - 1]

    this._emitChangeEvent()
    return {
      add: targetAction.added.map((item) => item.new),
      modify: targetAction.modified.map((item) => item.new),
      delete: targetAction.deleted.map((item) => item.old),
    }
  }

  get canUndo() {
    return this.depth < this.stack.length
  }

  get canRedo() {
    return this.depth > 0
  }

  _emitChangeEvent() {
    this.emit('undo:change', {
      canUndo: this.canUndo,
      canRedo: this.canRedo,
    })
  }
}

export default UndoStack
