import EventEmitter from './EventEmitter'
import log from 'loglevel'

/**
 * Stripped down version of SocketClient to support using WBEngine just as a canvas, without any socket connectivity
 * or object syncing. This class follows the same interface as SocketClient, but does not emit any events other than
 * `connected`.
 */
class SandboxSocketClient extends EventEmitter {
  constructor() {
    super()

    this.socket = false
  }

  connect(boardId, userId, userData) {
    if (this.socket) {
      throw new Error('Sandbox is already connected')
      return
    }

    this.socket = true
    this.emit('connect')
    log.info('[wbengine] Sandbox connected. Skipping socket connection.')

    return Promise.resolve()
  }

  disconnect() {
    if (!this.socket) return

    this.socket = false
  }

  get isConnected() {
    return this.socket
  }

  pushObject(object) {
    if (!this.socket) throw new Error('Sandbox is not connected')

    // The resolved object must have an `_id` property to be able to identify objects later for edits and removals.
    return Promise.resolve({ object, _id: Date.now() })
  }

  modifyObject(object) {
    if (!this.socket) throw new Error('Sandbox is not connected')

    return Promise.resolve(object)
  }

  removeObject(objectId) {
    if (!this.socket) throw new Error('Sandbox is not connected')

    return Promise.resolve()
  }

  sendUserPointer(position) {
    // No-op.
  }

  updateUserData(data) {
    // No-op.
  }
}

export default SandboxSocketClient
