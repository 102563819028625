import { io } from 'socket.io-client'
import log from 'loglevel'

import EventEmitter from './EventEmitter'

class SocketClient extends EventEmitter {
  constructor(options = {}) {
    super()

    this.socket = null
    this.options = options || {}
  }

  connect(boardId, userId, userData) {
    if (this.socket) {
      throw new Error('Socket is already connected')
      return
    }

    this.socket = io(this.options.host, {
      auth: {
        boardId,
        userId,
        userData,
      },
      ...this.options,
    })

    this.socket.on('connect', () => {
      log.info('[wbengine] Socket connected')
      this.emit('connect')
    })

    this.socket.on('disconnect', () => {
      log.info('[wbengine] Socket disconnected')
      this.emit('disconnect')
    })

    this.socket.on('board:objects', ({ objects, initial }) => {
      log.info(`[wbengine] Received ${objects.length} ${initial ? 'initial' : 'remote'} object(s)`)
      this.emit('board:objects', objects, initial)
    })

    this.socket.on('board:objects:seed', (objects) => {
      log.info(`[wbengine] Received ${objects.length} remote object(s)`)
      this.emit('board:objects:seed', objects)
    })

    this.socket.on('board:clear', () => {
      this.emit('board:clear')
    })

    this.socket.on('object:update', (object) => {
      log.info(`[wbengine] Received object update for ${object._id}`)
      this.emit('object:update', object)
    })

    this.socket.on('object:remove', (objectId) => {
      log.info(`[wbengine] Received object removal for ${objectId}`)
      this.emit('object:remove', objectId)
    })

    this.socket.on('user:list', (users) => {
      log.info(`[wbengine] Received user list in ${boardId}: ${Object.keys(users).length} users`)
      this.emit('user:list', users)
    })

    this.socket.on('user:connect', ({ userId, users }) => this.emit('user:connect', userId, users))
    this.socket.on('user:disconnect', ({ userId, users }) => this.emit('user:disconnect', userId, users))
    this.socket.on('user:position', (data) => this.emit('user:position', data))
    this.socket.on('user:change', ({ userId, userData }) => this.emit('user:change', userId, userData))

    return new Promise((resolve, reject) => {
      this.socket.once('connect', () => {
        resolve()
      })

      this.socket.on('connect_error', (err) => {
        log.error('[wbengine] Socket connection error', err)
        this.socket.close()
        this.socket = null
        reject(err)
      })
    })
  }

  disconnect() {
    if (!this.socket) return

    this.socket.close()
    this.socket = null
  }

  get isConnected() {
    return this.socket !== null
  }

  pushObject(object) {
    if (!this.socket) throw new Error('Socket is not connected')

    return new Promise((resolve, reject) => {
      const { type, localId } = object

      log.info(`[wbengine] Pushing one new object (type=${type}, localId=${localId}) to server`)

      // We don't want to propagate our localId, it's purely a local property
      const data = { ...object }
      delete data.localId

      this.socket.emit('object:push', data, (savedObject) => {
        if (savedObject === null) {
          log.error(`[wbengine] Server returns error pushing object ${localId}`)
          reject(new Error('server error'))
        } else {
          log.info(`[wbengine] Server confirms object push for ${type}: local ${localId} → remote ${savedObject._id}`)
          resolve(savedObject)
        }
      })
    })
  }

  modifyObject(object) {
    if (!this.socket) throw new Error('Socket is not connected')
    if (!object._id) throw new Error(`Local object ${object.localId} has not been pushed to the server before`)

    return new Promise((resolve, reject) => {
      log.info(`[wbengine] Sending modification of object ${object.type} with id ${object._id} to server`)
      this.socket.emit('object:modify', object, (response) => {
        if (response === null) {
          log.error(`[wbengine] Server returns error modifying object ${object._id}`)
          reject(new Error('server error'))
        } else {
          log.info(`[wbengine] Server confirms  modification of ${object._id}`)
          resolve(response)
        }
      })
    })
  }

  removeObject(objectId) {
    if (!this.socket) throw new Error('Socket is not connected')

    return new Promise((resolve, reject) => {
      log.info(`[wbengine] Removing object ${objectId} on server`)
      this.socket.emit('object:remove', objectId, (response) => {
        if (response) {
          resolve()
        } else {
          reject(new Error('Server rejected object removal'))
        }
      })
    })
  }

  sendUserPointer(position) {
    if (!this.socket) return
    this.socket.volatile.emit('user:position', position)
  }

  updateUserData(data) {
    if (!this.socket) return

    this.socket.emit('user:change', data)
  }
}

export default SocketClient
