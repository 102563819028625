import * as fabric from "fabric";

class Image {
  constructor(wb) {
    this.wb = wb;
  }

  get canvas() {
    return this.wb.canvasWrapper.canvas;
  }

  async addFromURL(url) {
    const img = await fabric.Image.fromURL(url)
    if (!img) {
      return new Error(`Failed to add image from ${url}`)
    }
    this.canvas.add(img)
    return img
  }
}

export default Image;
