import log from 'loglevel'

import EventEmitter from './EventEmitter'

const REQUIRED_HANDLERS = ['create', 'update']
const POINTER_DELAY = 250 // in ms

class UserPointers extends EventEmitter {
  constructor(socketClient, canvasWrapper, pointerHandlers) {
    super()

    this.handlers = UserPointers._checkHandlers(pointerHandlers)

    this.pointers = {}
    this.userId = null
    this.socketClient = socketClient
    this.canvasWrapper = canvasWrapper

    this.socketClient.on('user:connect', (userId, users) => this.updateUsers(users))
    this.socketClient.on('user:disconnect', (userId, users) => this.updateUsers(users))
    this.socketClient.on('user:list', (users) => this.updateUsers(users))
    this.socketClient.on('user:position', (data) => this.onUserPosition(data))
    this.socketClient.on('user:change', (userId, userData) => this.onUserChange(userId, userData))
    this.canvasWrapper.on('event', (name, data) => this.onCanvasEvent(name, data))

    this.pendingPointerUpdate = null

    this.container = canvasWrapper.wrapperElement
    this.container.style.position = 'relative'
    this.container.style.overflow = 'hidden'
  }

  static _checkHandlers(handlers) {
    for (let name of REQUIRED_HANDLERS) {
      if (!handlers[name]) throw new Error(`User pointer handler ${name} is not set`)
    }
    return handlers
  }

  setUserId(userId) {
    this.userId = userId
  }

  updateUsers(users) {
    for (let userId in users) {
      /* Don't create pointer for ourselves. This has the effect of not showing
       * own pointer in other windows if the same user is connected multiple
       * times. If this use case is supported, remove the guard so that self
       * is not omitted from pointers and optionally add an additional data
       * attribute to the pointer element so it can be styled differently to
       * make it obvious it's the user's pointer.
       */
      if (userId === this.userId) continue

      const user = users[userId]

      if (userId in this.pointers) {
        this.handlers.update(this.pointers[userId], { ...user, userId })
      } else {
        const el = this.handlers.create({ ...user, userId })
        el.style.position = 'absolute'
        el.style.pointerEvents = 'none'

        this.handlers.update(el, { ...user, userId })
        this.container.appendChild(el)
        this.pointers[userId] = el
      }
    }

    for (let userId in this.pointers) {
      if (users[userId]) continue

      const el = this.pointers[userId]

      if (this.handlers.dispose) {
        this.handlers.dispose(el, userId)
      }

      this.container.removeChild(el)
      delete this.pointers[userId]
    }
  }

  /* Updates pointer position, constrained by the visible viewport, and
   * sets edge flags so the pointer can be changed if the actual location
   * is beyond the viewport edges.
   */
  _updatePointerPosition(el, x, y) {
    const between = (value, lower, upper) => Math.min(Math.max(value, lower), upper)

    if (x == null || y == null) {
      el.style.opacity = 0
      return
    }

    const box = el.getBoundingClientRect()
    const containerBox = this.container.getBoundingClientRect()
    const maxWidth = containerBox.width - box.width
    const maxHeight = containerBox.height - box.height

    let onEdge = ''
    if (y < 0) onEdge = 't'
    if (y > maxHeight) onEdge = 'b'
    if (x < 0) onEdge = onEdge + 'l'
    if (x > maxWidth) onEdge = onEdge + 'r'

    const left = between(x, 0, maxWidth)
    const top = between(y, 0, maxHeight)

    if (onEdge !== '') {
      el.setAttribute('data-edge', onEdge)
    } else {
      el.removeAttribute('data-edge')
    }

    el.style.left = `${left}px`
    el.style.top = `${top}px`
    el.style.opacity = 1
  }

  /* Send user pointer position. Send can be queued up to POINTER_DELAY,
   * after which the last position recorded is sent. This is to avoid
   * flooding the server with too many pointer updates.
   */
  sendUserPointer(position) {
    const alreadyQueued = this.pendingPointerUpdate !== null
    this.pendingPointerUpdate = position

    if (alreadyQueued) return

    setTimeout(() => {
      this.socketClient.sendUserPointer(this.pendingPointerUpdate)
      this.pendingPointerUpdate = null
    }, POINTER_DELAY)
  }

  onCanvasEvent(name, data) {
    if (name !== 'mouse:move') return
    this.sendUserPointer(data)
  }

  onUserPosition(data) {
    const { userId, x, y } = data

    if (userId === this.userId) return

    const pointer = this.pointers[userId]
    if (!pointer) {
      log.error(`[wbengine] Got position for unknown user ${userId}`)
      return
    }

    /* Coordinates we get are in absolute canvas coordinate system. If we've
     * been zoomed or panned locally, we need to transformed them to screen
     * coordinates (still relative to canvas/wrapper origin).
     */
    const p = this.canvasWrapper.transformPoint({ x, y })
    this._updatePointerPosition(pointer, p.x, p.y)
  }

  onUserChange(userId, userData) {
    const pointer = this.pointers[userId]

    if (pointer) {
      this.handlers.update(pointer, userData)
    }
  }
}

export default UserPointers
