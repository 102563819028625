export function darkenHexColor(hexColor, percentage) {
  // Parse the hex color to RGB components
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);

  // Calculate the darken amount
  const darkenAmount = 1 - (percentage / 100);

  // Darken the RGB values
  const darkenedRed = Math.round(red * darkenAmount);
  const darkenedGreen = Math.round(green * darkenAmount);
  const darkenedBlue = Math.round(blue * darkenAmount);

  // Convert the darkened RGB values to hex form
  const darkenedHex = `#${darkenedRed.toString(16).padStart(2, '0')}${darkenedGreen.toString(16).padStart(2, '0')}${darkenedBlue.toString(16).padStart(2, '0')}`;

  return darkenedHex;
}

export function getAbsoluteCenterPosition(object) {
  const transformMatrix = object.calcTransformMatrix()
  return { x: transformMatrix[4], y: transformMatrix[5] }
}

export function getDistanceSquared(x1, y1, x2, y2) {
  return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2)
}