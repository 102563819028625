import * as fabric from 'fabric'

class Text {
  constructor(wb) {
    this.wb = wb
    this.helper = null
  }

  get canvas() {
    return this.wb.canvasWrapper.canvas
  }

  get options() {
    return this.wb.toolOptions || {}
  }

  dispose() {
    this._disposeHelper()
  }

  _disposeHelper() {
    if (this.helper) {
      this._commitText()
      this.helper.off('editing:exited')
      this.canvas.remove(this.helper)
      this.helper = null
    }
  }

  handle(eventName, data) {
    switch (eventName) {
      case 'mouse:up':
        this.onMouseUp(data)
        break
      case 'panzoom:before':
        this.disableHelperCursor()
        // Need WhiteboardEngine to emit this event so zoom listeners are informed.
        this.wb.emit(eventName, data)
        break
      case 'panzoom':
        this.enableHelperCursor()
        // Need WhiteboardEngine to emit this event so zoom listeners are informed.
        this.wb.emit(eventName, data)
        break
      default:
        // If not handled by this tool, let WhiteboardEngine emit the event.
        this.wb.emit(eventName, data)
    }
  }

  onMouseUp({ x, y }) {
    if (this.helper) return

    this.helper = new fabric.IText('', {
      ...this.options,
      left: x,
      top: y,
      excludeFromExport: true,
    })

    this.helper.on('editing:exited', () => {
      this.onDone()
    })

    this.canvas.add(this.helper)
    this.canvas.setActiveObject(this.helper)
    this.helper.enterEditing()
    this.helper.selectAll()
    this.helper.set({
      left: x,
      top: y - this.helper.height / 2, // vertically-centered on where the user clicked
    })
  }

  onDone() {
    // _disposeHelper will handle commiting the helper's text.
    this._disposeHelper()
    this.wb.setMode('select')
  }

  _commitText() {
    // Only create an object if something's actually written
    if (this.helper && this.helper.text) {
      this.canvas.add(
        new fabric.IText(this.helper.text, {
          ...this.options,
          top: this.helper.top,
          left: this.helper.left,
          fontFamily: this.helper.fontFamily,
          fontSize: this.helper.fontSize,
          fontWeight: this.helper.fontWeight,
          fontStyle: this.helper.fontStyle,
          underline: this.helper.underline,
          linethrough: this.helper.linethrough,
          textAlign: this.helper.textAlign,
          stroke: this.helper.stroke,
          fill: this.helper.fill,
        })
      )
    }
  }

  enableHelperCursor() {
    if (!this.helper) return
    this.helper.restartCursorIfNeeded()
  }

  disableHelperCursor() {
    if (!this.helper) return
    this.helper.abortCursorAnimation()
  }
}

export default Text
